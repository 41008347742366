import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import Button from "components/Button";
import {ReactComponent as IconPlus} from "assets/icons/plus.svg";
import {ReactComponent as IconMinus} from "assets/icons/minus.svg";
import {ROUTES} from "App";
import {useHistory} from "react-router-dom";

const cx = classNames.bind(styles);

type QuestionType = {
  question: string;
  answer: string;
};

const Faq = () => {
  const [t] = useTranslation();
  const questions: QuestionType[] = t("pageHome.faq.questions", {
    returnObjects: true,
  });

  return (
    <section className={cx("Component")}>
      <div className={cx("container")}>
        {questions.map((data, i) => (
          <Question {...data} key={i}/>
        ))}
      </div>
    </section>
  );
};

export const Question = ({
                           question,
                           answer,
                           className = "",
                           dangerousHTML,
                           hideBtn = false,
                         }: QuestionType & {
  dangerousHTML?: boolean;
  className?: string;
  hideBtn?: boolean;
}) => {
  const [t] = useTranslation();
  const [show, setShow] = useState(false);
  const history = useHistory();
  const goToTest = () => history.push(ROUTES.test);
  const handleClickPlus = () => {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [] as any
    // @ts-ignore
    window.dataLayer.push({
      event: 'click_btn',
      btn_name: question
    })
  }
  const handleClick = () => {
    setShow(!show)
  }
  return (
    <div className={cx("Question", className)}>
      <div onClick={handleClick} className={cx("QuestionHeader")}>
        <h3 className={cx("QuestionName")}>{question}</h3>

        <span className={cx("QuestionToggleBtn")} onClick={handleClickPlus}>
          {show ? <IconMinus/> : <IconPlus/>}
        </span>
      </div>

      <div className={cx("QuestionAnswer", show && "show")}>
        {dangerousHTML ? (
          <p dangerouslySetInnerHTML={{__html: answer}}/>
        ) : (
          <p>{answer}</p>
        )}
        {!hideBtn && (
          <Button onClick={goToTest} className={cx("QuestionBtn")}>
            {t("pageHome.faq.btn")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Faq;

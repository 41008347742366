import React, {useEffect, useState} from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import {useTranslation} from "react-i18next";
import Intro from "./Intro";
import Registration, {Props as RegistrationProps} from "./Registration";
import Survey from "./Survey";
import {SocialNetworksMutationVariables, StartSurveyMutation, useSurveyQuery,} from "api/generated";
import Preloader from "components/Preloader";
import {RouteComponentProps} from "react-router-dom";
import {ROUTES} from "App";
import Button from "components/Button";
import {PlayVideo} from "../../components/PlayVideo";
import {CodeQueryURL} from "../../api/state";
import {useURL} from "../../hooks/useURL"

const cx = classNames.bind(styles);

export type Step =
  | "intro"
  | "registration"
  | "survey"
  | "final-registration"
  | "result";
export type SurveyType = NonNullable<StartSurveyMutation["startSurvey"]>["survey"];

type StoredSurveyData = {
  uid: string;
};

export type ProfileData = {
  /** Text field params */
  email?: string;
  lastName?: string;
  firstName?: string;
  age?: string;
} & Omit<SocialNetworksMutationVariables, "suid">;

export const SURVEY_DATA_KEY = "surveyData";
// @ts-ignore
window.dataLayer = window.dataLayer || [] as any

const PageTest = ({history}: RouteComponentProps) => {
  const [t, {language: lang}] = useTranslation();
  const [step, setStep] = useState<Step>("intro");
  const [start, setStart] = useState(true)
  const [profileData, setProfileData] = useState<undefined | ProfileData>();
  const [survey, setSurvey] = useState<SurveyType>();
  let code = useURL().get("code")
  useEffect(() => {
    if (code) {
      CodeQueryURL(code)
    } else if (localStorage.getItem('code')) {
      code = localStorage.getItem('code')
      CodeQueryURL(code ?? '')
    }
  }, [])
  const [loadingSurvey, setLoadingSurvey] = useState(true);

  const surveyQuery = useSurveyQuery({skip: true});

  const onClickStart = () => {
    setStep("registration");
  };

  const onDoneSurvey = (id: string) => {
    history.push(ROUTES.result(id));
    localStorage.removeItem('code');
    sessionStorage.removeItem(SURVEY_DATA_KEY);
  };

  const onSubmitRegistration: RegistrationProps["onSubmitRegistration"] = (
    survey,
    profileData
  ) => {
    if (start) {
      // @ts-ignore
      window.dataLayer.push({
        event: 'formstart',
      })
      setStart(false)
    }
    if (step === "final-registration") {
      setStep("result");
      // @ts-ignore
      window.dataLayer.push({
        event: 'formfinish',
      })

      // @ts-ignore
      if (profileData.suid) {
        // @ts-ignore
        return onDoneSurvey(profileData.suid);
      }

      sessionStorage.removeItem(SURVEY_DATA_KEY);
    } else {
      if (survey?.isFinished) {
        setStart(true)
        return onDoneSurvey(survey.uid);
      }
      setSurvey(survey);
      setProfileData(profileData);
      setStep("survey");
    }
  };
  useEffect(() => {
    if (survey) {
      try {
        const stored = sessionStorage.getItem(SURVEY_DATA_KEY);
        sessionStorage.setItem(
          SURVEY_DATA_KEY,
          JSON.stringify({
            ...(stored ? JSON.parse(stored) : {}),
            uid: survey.uid,
          } as StoredSurveyData)
        );
      } catch (e) {
      }
    }
  }, [survey]);

  useEffect(() => {
    try {
      const storedSurveyData: StoredSurveyData = JSON.parse(
        sessionStorage.getItem(SURVEY_DATA_KEY) ?? ""
      );

      if (storedSurveyData.uid) {
        setLoadingSurvey(true);
        surveyQuery
          .refetch({
            lang,
            uid: storedSurveyData.uid,
          })
          .then(({data}) => {
            if (data.survey?.isFinished) return onDoneSurvey(data.survey.uid);

            setSurvey(data.survey);
            setProfileData({
              email: data.survey?.email ?? "",
              firstName: data.survey?.firstName ?? "",
              googleToken: data.survey?.googleToken ?? "",
              facebookToken: data.survey?.facebookToken ?? "",
            });
            setLoadingSurvey(false);

            setStep(
              data.survey?.currentQuestion ? "survey" : "final-registration"
            );
          });
      } else {
        setLoadingSurvey(false);
      }
    } catch {
      setLoadingSurvey(false);
    }
  }, []);

  const onAnswerLastQuestion = () => {
    setStep("final-registration");
  };

  if (loadingSurvey) return <Preloader/>;

  if (step === "result")
    return (
      <div className={cx("Result")}>
        <img src={require("assets/images/rocket.png")} alt=""/>
        <p className={cx("ResultText")}>{t("pageTest.result.text")}</p>
        <Button onClick={() => history.push(ROUTES.home)}>
          {t("pageTest.result.btn")}
        </Button>
      </div>
    );

  return (
    <div className={cx("Page")}>

      <PlayVideo className={cx("Video")}/>
      {step === "intro" && <Intro onClickStart={onClickStart}/>}
      {(step === "registration" || step === "final-registration") && (
        <Registration
          step={step}
          suid={survey?.uid}
          profileData={profileData ?? {}}
          onSubmitRegistration={onSubmitRegistration}
        />
      )}
      {step === "survey" && (
        <Survey
          survey={survey!}
          profileData={profileData!}
          initialProgress={{
            num: survey?.currentAnswer?.num ?? 0,
            total: survey?.questionsTotal ?? 1,
          }}
          onAnswerLastQuestion={onAnswerLastQuestion}
        />
      )}
    </div>
  );
};

export default PageTest;

import React, {Fragment, ReactNode, useEffect, useRef, useState} from "react";
import {Swipeable} from "react-swipeable";
import styles from "./index.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

type Props = {
  slides: ReactNode[];
  // it can be controlled component or not
  setCurrentSlide?: React.Dispatch<React.SetStateAction<number>>;
  currentSlide?: number;
  className?: string;
  dots?: boolean;
  autoHeight?: boolean,
  classNamesDots?: string
};

const Slider = ({slides, dots = true, className, classNamesDots, ...props}: Props) => {
  slides = slides.filter(e => e);
  const itemsCount = slides.length;
  let [currentSlide, setCurrentSlide] = useState(0);
  if (props.setCurrentSlide && typeof props.currentSlide === "number") {
    currentSlide = props.currentSlide;
    setCurrentSlide = props.setCurrentSlide;
  }
  const sliderRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!sliderRef.current) return;
    let correctSlideNumber = currentSlide;
    if (correctSlideNumber >= itemsCount) {
      correctSlideNumber = 0;
    } else if (correctSlideNumber < 0) {
      correctSlideNumber = itemsCount - 1;
    }
    const slides = (sliderRef.current.childNodes ?? []) as NodeListOf<HTMLElement & any>;
    let translateX = 0;
    let height = 400;
    for (let i = 0; i < slides.length; i++) {
      if (i < correctSlideNumber) {
        translateX += slides[i].offsetWidth;
      }
      if (i === correctSlideNumber) {
        const el = slides[i]?.childNodes[0]
        height = el?.offsetHeight
      }
    }
    setCurrentSlide(correctSlideNumber);
    sliderRef.current.style.transform = `translateX(-${translateX}px)`;
    if (props.autoHeight && window.innerWidth < 768) {
      sliderRef.current.style.height = `${height}px`;
    }
  }, [currentSlide]);

  const onClickNextSlide = () => {

    let nextSlide = currentSlide + 1;
    setCurrentSlide(nextSlide);
  };

  const onClickPrevSlide = () => {
    let nextSlide = currentSlide - 1;
    setCurrentSlide(nextSlide);
  };
  return (
    <Fragment>
      <Swipeable
        style={{overflow: "hidden"}}
        trackMouse
        trackTouch
        preventDefaultTouchmoveEvent={true}
        onSwipedLeft={onClickNextSlide}
        onSwipedRight={onClickPrevSlide}
      >
        <div className={cx("Slider", "container", className)} ref={sliderRef}>
          {slides.map((slide, i) => {
            return (
              <div key={i} className={cx("SlideWrapper", {"SlideActive": currentSlide === i})}>
                <div className={cx("Slide")}>{slide}</div>
              </div>
            );
          })}
        </div>
      </Swipeable>
      {dots && (
        <div className={`${cx("SliderDots")}${classNamesDots ? ' ' + classNamesDots : ''}`}
        >
          {slides.map((_, index) => {
            return (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                tabIndex={index}
                type="button"
                className={cx(
                  "SliderBtnDot",
                  index === currentSlide && "active"
                )}
              />
            );
          })}
        </div>
      )}
    </Fragment>
  );
};

export default Slider;

import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
const cx = classNames.bind(styles);

const QuestionBoolean = ({
  text = "",
  onSubmit
}: {
  text: string;
  onSubmit: (answer: boolean) => void;
}) => {
  const [t] = useTranslation();
  return (
    <div className={cx("Component")}>
      <p className={cx("Text")}>{text}</p>

      <div className={cx("Actions")}>
        <Button type="button" onClick={() => onSubmit(true)} withArrow={false}>
          {t("pageTest.actions.yes")}
        </Button>
        <Button type="button" onClick={() => onSubmit(false)} withArrow={false}>
          {t("pageTest.actions.no")}
        </Button>
      </div>
    </div>
  );
};

export default QuestionBoolean;

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import App from "./App";
import "styles/index.scss";
import "locale";
import {AppProvider} from "AppContext";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({dsn: process.env.REACT_APP_SENTRY_DNS});
}


ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App/>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

import React, {useEffect} from "react";
import Intro from "./Intro";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import Participants from "./Participants";
import {useTranslation} from "react-i18next";
import Ticker from "./components/Ticker";
import Model from "./Model";
import {useURL} from "../../hooks/useURL";
import {CodeQueryURL} from "../../api/state";
import TextSectionFrame from "./components/TextSectionFrame";
import Action from "./Action";
import Price from "./Price";

const cx = classNames.bind(styles);

const PageLanding = () => {
  const [t] = useTranslation();
  const code = useURL().get("code")
  useEffect(() => {
    if (code) {
      CodeQueryURL(code)
    }
  }, [])

  return (
    <main className={cx("Component")}>
      <Intro/>
      <Model/>
      <Ticker animationDuration={'180s'} message={t("pageHome.ticker.brand")}/>
      <TextSectionFrame
        id="about-test"
        text={t("pageHome.brand.text", {returnObjects: true})}
      />
      {/*<TextSection*/}
      {/*  text={t("pageHome.about.text", {returnObjects: true})}*/}
      {/*  btnText={t("pageHome.about.btn")}*/}
      {/*/>*/}
      <Participants/>
      {/*<Chat/>*/}
      <Ticker message={t("pageHome.ticker.create")}/>
      {/*<Price />*/}
      <Action/>
      {/*<Propose/>*/}
      {/*<Benefits/>*/}
      {/*<Ticker message={t("pageHome.ticker.target")}/>*/}
      {/*<Faq/>*/}

    </main>
  );
};

export default PageLanding;

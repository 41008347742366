import {OrderStatus, Query, QueryOrderStatusArgs,} from "api/generated";
import Preloader from "components/Preloader";
import PageError from "pages/Error";
import React from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {ORDER_STATUS} from "api/queries/ORDER_STATUS";
import {StatusResult} from "../../components/StatusResult";
import {ROUTES} from "../../App";
import CheckStatus from "components/CheckStatus";

export const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};
const PageResultPayment = ({
                             match: {
                               params: {
                                 order_uid,
                                 uid
                               },
                             },
                           }: RouteComponentProps<{ uid: string, order_uid: string }>) => {

  const {data, loading, error, startPolling, stopPolling} = useQuery<Query, QueryOrderStatusArgs>(ORDER_STATUS, {
    variables: {uid: order_uid},
  })
  const {push} = useHistory();
  const status = data?.orderStatus?.status
  if (error) return <PageError/>;
  if (loading) {
    return <Preloader/>;
  }
  if (status === OrderStatus.Pending) {
    startPolling(2000);
    return <CheckStatus />
  }
  if (status === OrderStatus.Failure || status === OrderStatus.Decline) {
    stopPolling();
    return <div>
      <StatusResult result={OrderStatus.Failure}/>
    </div>
  }
  if (status === OrderStatus.Success) {
    stopPolling();
    push(ROUTES.result(uid))
  }
  return <Preloader/>;
}

export default PageResultPayment;

import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import Logo from "assets/logo.svg";
import {SidebarControl} from "components/Sidebar";
import {useTranslation} from "react-i18next";
import {Languages, setStoredLng} from "locale";
import AutoplayVideo from "components/AutoplayVideo";
import ButtonWithStar from "components/ButtonWithStar";
import {PRICE} from "constants/constants";
import {useHistory} from "react-router-dom";
import {ROUTES} from "App";

const cx = classNames.bind(styles);

const Intro = () => {
  const {t, i18n} = useTranslation();
  const history = useHistory()
  const goToTest = () => history.push(ROUTES.test)

  const changeLng = (lng: Languages) => {
    setStoredLng(lng);
    i18n.changeLanguage(lng);
  };
  const scrollToPrice = () => {
    // const section = document.getElementById('price');
    // section && section.scrollIntoView(true)
  }

  return (
    <section className={cx("Component")}>

      <AutoplayVideo src={"/assets/video/intro.mp4"}
                     classNames={cx("Video")}
                     imagePath={"/assets/video/intro.mp4"}/>

      <div className={cx("container", "Container")}>
        <header className={cx("Header")}>
            <span className={cx("Logo")}>
              <img src={Logo} alt="Fly_academy"/>
            </span>

          <div className={cx("Actions")}>
            <div className={cx("LangSwitcher")}>
              <button
                type="button"
                className={cx(i18n.language === "ru" && "active")}
                onClick={() => changeLng("ru")}
              >
                {t('ru')}
              </button>
              <button
                type="button"
                className={cx(i18n.language === "uk" && "active")}
                onClick={() => changeLng("uk")}
              >
                {t('uk')}

              </button>

              <button
                type="button"
                className={cx(i18n.language === "en" && "active")}
                onClick={() => changeLng("en")}
              >
                {t('en')}

              </button>
            </div>

            <SidebarControl/>
          </div>
        </header>

        <div className={cx("Content")}>
          <h1 className={cx("Title")}>{t("pageHome.intro.message")}</h1>
          <h2 className={cx("Subtitle")}>
            {t("pageHome.intro.description")}
          </h2>
          <ButtonWithStar onClick={goToTest}
                          withArrow={false}
                          oldPrice={PRICE.old}
                          currency={PRICE.currency}
                          newPrice={PRICE.new}/>
        </div>
      </div>
    </section>
  );
};

export default Intro;

import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import s from './index.module.scss';
import classNamesBind from "classnames/bind";

const cx = classNamesBind.bind(s)
const CheckStatus = () => {
  const [t] = useTranslation()
  return <div className={cx('CheckStatus')}>
    <div className={cx("loader")}>
      <div>
        <div></div>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p className={cx("CheckStatusText")}>{t('checkStatus')}</p>
  </div>
}
export default memo(CheckStatus)
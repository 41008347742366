import React, {FC, Fragment, useEffect, useState} from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import {Redirect, RouteComponentProps} from "react-router-dom";
import {CurrencyEnum, LanguageEnum, ProviderEnum, useCreateOrdeMutation, useSurveyResultQuery,} from "api/generated";
import Preloader from "components/Preloader";
import PageError from "pages/Error";
import Ticker from "pages/Landing/components/Ticker";
import {Trans, useTranslation} from "react-i18next";
import {FacebookShareButton, TwitterShareButton} from "react-share";
import {ReactComponent as IconFB} from "assets/icons/fb_logo.svg";
import {ReactComponent as IconTwitter} from "assets/icons/twitter_logo.svg";
import Button from "components/Button";
import {ReactComponent as IconArrowLeft} from "assets/icons/arrow_sm-left.svg";
import {ReactComponent as IconArrowRight} from "assets/icons/arrow_sm-right.svg";
import {BenefitsList as List} from "pages/Landing/Benefits";
import Slider from "pages/Landing/components/Slider";
import {Question} from "pages/Landing/Faq";
import {parseError} from "api";
import {isMobile} from "pages/ResultPayment";
import downloadFile from "../../utils/downloadFile";
import ButtonWithStar from "components/ButtonWithStar";
import {PRICE} from "../../constants/constants";
import {SharePropsType} from "./index.props";
import Yourself from "./Yourself";
import {goWorkbook} from "../../utils/goWorkbook";

const cx = classNames.bind(styles);

const checkWidth = () => window.innerWidth < 1440;

const PageResult = ({
                      match: {
                        params: {uid},
                      },
                      location: {
                        search
                      }
                    }: RouteComponentProps<{ uid: string }>) => {
  const [isTablet, setIsTablet] = useState(checkWidth());
  useEffect(() => {
    window.onresize = () => setIsTablet(checkWidth);
    return () => {
      window.onresize = null;
    };
  }, []);

  const [t, {language}] = useTranslation();
  const lang = language;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [errorCreateOrder, setErrorCreateOrder] = useState("");
  const [isLoading, setLoading] = useState(false)
  const [
    createOrderMutation,
    {loading: loadingCreateOrder},
  ] = useCreateOrdeMutation();

  const onClickNextSlide = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const onClickPrevSlide = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const {loading, error, data} = useSurveyResultQuery({
    variables: {uid, lang},
  });
  const propseList: string[] = t("pageResult.propose.list", {
    returnObjects: true,
  });
  const planList: string[] = t("pageResult.plan.personal.question", {
    returnObjects: true,
  });

  if (loading) return <Preloader/>;

  if (error || !data?.survey?.result || !data.survey.isFinished)
    return <PageError/>;

  if (search.includes("share")) {
    return <Redirect to={'/'}/>
  }
  const onClickCreateOrder = () => {
    setErrorCreateOrder("");

    const onErrorReceived = (msg?: string) => {
      setErrorCreateOrder(`${t("error.globalError")}${msg ? `: ${msg}` : ""}`);
    };

    createOrderMutation({
      variables: {
        language: language === "ru" ? LanguageEnum.Ru : LanguageEnum.Uk,
        provider: ProviderEnum.Fondy,
        currency: CurrencyEnum.Usd,
        suid: uid,
      },
    })
      .then(({data}) => {
        if (data?.createOrder?.order?.link) {
          if (isMobile()) {
            window.location.assign(data.createOrder.order.link);
          } else {
            window.open(data.createOrder.order.link, "_blank");
          }
        } else {
          onErrorReceived();
        }
      })
      .catch((e) => {
        onErrorReceived(parseError(e).message);
      });
  };
  const survey = {...data.survey, isPaid: true};
  const role = survey.result?.role;
  const hashtag = `${lang.toLowerCase() === "ru" ? "моямодельличности" : "моямодельособистості"}`
  const videoPath = `https://personalbrandmodel.com/assets/video/`;
  const videoUrl = `${videoPath}${role?.toLowerCase()}.mp4`;
  const videoDownloadUrl = `${videoPath}${role?.toLowerCase()}_share.mp4`;

  const onClickDownloadBtn = async () => {
    try {
      setLoading(true)
      await downloadFile(videoDownloadUrl, 'personalbrandmodel', 'mp4', 'video/mp4')
    } catch (e) {
      console.error(e.message)
    } finally {
      setLoading(false)
    }
  };

  const Share: FC<SharePropsType> =
    ({
       className = "",
       children,
       isNew = false
     }) => (
      <div className={cx("IntroShare", className)}>

        {!isNew && <h6 className={cx("IntroShareTitle")}>
          {t("pageResult.intro.share.title")}
        </h6>}

        <div className={cx("IntroShareActions")}>
          <FacebookShareButton
            hashtag={hashtag}
            quote={survey.result?.name!}
            className={cx("IntroShareBtn")}
            url={`${window.location.origin}/result/${uid}/share?lang=${lang}`}
          >
            <IconFB/>
          </FacebookShareButton>
          <TwitterShareButton
            className={cx("IntroShareBtn")}
            hashtags={[hashtag]}
            title={survey.result?.name!}
            url={`${window.location.origin}/result/${uid}/share?lang=${lang}`}
          >
            <IconTwitter/>
          </TwitterShareButton>

          <Button
            loading={isLoading}
            // onClick={onClickDownloadBtn}
            onClick={() => goWorkbook(language as LanguageEnum)}
            className={cx("IntroShareDownloadBtn", {BtnNew: isNew})}
            withArrow={false}
          >
            {/*{t("pageResult.intro.share.btn")}*/}
            {t("Your workbook")}

            {/*<img src={require("assets/icons/download.svg")} alt=""/>*/}
          </Button>
        </div>

        {children}
      </div>
    );

  return (
    <div className={cx("Component")}>
      <section className={cx("Intro", role)}>
        <Ticker
          oneLine
          className={cx("IntroTicker")}
          message={t("pageResult.ticker.intro", {role: survey.result?.name})}
        />

        <div className={cx("IntroContainer", "container")}>
          <div className={cx("IntroMain")}>
            <div className={cx("IntroVideo")}>
              <div className={cx("IntroVideoOverlayBlock_t", "IntroVideoOverlayBlock_t" + role)}/>
              <div className={cx("IntroVideoOverlayBlock_b", "IntroVideoOverlayBlock_b" + role)}/>
              <div className={cx("IntroVideoOverlayBlock_l", "IntroVideoOverlayBlock_l" + role, "laptop")}/>
              <div className={cx("IntroVideoOverlayBlock_r", "IntroVideoOverlayBlock_r" + role, "laptop")}/>
              <video autoPlay playsInline muted src={videoUrl}/>
              {!survey.isPaid && <ButtonWithStar onClick={onClickCreateOrder}
                                                 white
                                                 oldPrice={PRICE.old}
                                                 newPrice={PRICE.new}
                                                 withArrow={false}

                                                 classNamesWrap={cx('ButtonTablet')}
                                                 text={'personalityBrandStrategy'}


              />}
            </div>

            <div
              className={cx("IntroDescription")}
            >
              <div className={cx("IntroDescriptionText")}
                   dangerouslySetInnerHTML={{__html: survey.result?.description!}}/>
              <div className={cx("IntroDescriptionOverlay", "IntroDescriptionOverlay" + role)}/>
            </div>
          </div>
          {!survey.isPaid && <div className={cx('ShareWrap')}>
            <ButtonWithStar onClick={onClickCreateOrder}
                            white
                            oldPrice={PRICE.old}
                            newPrice={PRICE.new}
                            withArrow={false}

                            classNamesWrap={cx('ButtonWrap')}
                            text={'personalityBrandStrategy'}


            />
            <Share isNew={true}/>

          </div>}
          {survey.isPaid && <Share/>}

        </div>
      </section>

      {survey.isPaid && <section className={cx("Properties")}>
        <div className={cx("PropertiesContainer")}>
          <h2 className={cx("PropertiesTitle")}>
            {t("pageResult.properties.title")}
          </h2>

          <div className={cx("PropertiesList", "container")}>
            {survey.result?.properties?.map((prop, i) => (
              <div key={i} className={cx("PropertiesItem")}>
                <div className={cx("PropertiesItemHead", prop?.color)}>
                  <span/>
                  <span/>
                  <span/>
                </div>
                <p dangerouslySetInnerHTML={{__html: prop?.text ?? ""}}/>
              </div>
            ))}
          </div>
        </div>
      </section>}

      <section className={cx("Congratulation", {"CongratulationFree": !survey.isPaid})}>
        <div className={cx("CongratulationContainer", "container")}>
          <div>
            <h3 className={cx('CongratulationTitle')}>{t("pageResult.congratulation.title")}</h3>
            <p className={cx('CongratulationDescription')}>{t("pageResult.congratulation.description")}</p>
          </div>
          {/*<img*/}
          {/*  src={require("assets/images/result_congratulation.svg")}*/}
          {/*  alt=""*/}
          {/*/>*/}
        </div>
      </section>

      {survey.isPaid && (
        <section className={cx("Content")}>
          <Ticker
            oneLine
            className={cx("ContentTicker")}
            message={t("pageResult.ticker.content")}
          />
          <div className={cx("ContentContainer", "container")}>
            <div className={cx("ContentSliderArrows")}>
              <button
                className={cx("ContentSlideBtnArrowPrev")}
                type="button"
                onClick={onClickPrevSlide}
              >
                <IconArrowLeft/>
              </button>
              <button
                className={cx("ContentSlideBtnArrowNext")}
                type="button"
                onClick={onClickNextSlide}
              >
                <IconArrowRight/>
              </button>
            </div>
          </div>

          <Slider
            dots={isTablet}
            autoHeight={true}
            className={cx("ContentSlider")}
            slides={
              survey.result?.userContents?.map((content, i) => {
                return (
                  <div className={cx("ContentItem", content?.color)}>
                    <h5>{content?.name ?? ""}</h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: content?.text ?? "",
                      }}
                    ></p>
                  </div>
                );
              }) ?? []
            }
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
          />
        </section>
      )}

      {survey.isPaid && (
        <section className={cx("Model")}>
          <div className={cx("ModelContainer", "container")}>
            <div className={cx("ModelDetails")}>
              <h3>
                {t("pageResult.model.title", {role: survey.result?.acquiredName})}
              </h3>
              <div className={cx("ModelImg")}>
                <img
                  src={require(`assets/images/model-${role?.toLowerCase()}.png`)}
                  alt=""
                />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: survey.result?.acquiredText ?? "",
                }}
              />
            </div>
          </div>
        </section>)
      }
      {survey.isPaid && (

        <Yourself role={survey.result?.role}
                  isTablet={isTablet}
        />
      )}
      {survey.isPaid && (
        <section className={cx("Plan")}>
          <Ticker
            oneLine
            className={cx("PlanTicker")}
            message={t("pageResult.ticker.plan")}
          />

          <div className={cx("PlanContainer", "container")}>
            <p className={cx("PlanDescription")}>
              <Trans i18nKey="pageResult.plan.description">
                <a target={"_blank"} href="https://flyacademy.co"></a>
              </Trans>
            </p>

            <div className={cx("PlanQuestionList")}>
              {survey.result?.actions?.map((action, i) => (
                <Question
                  dangerousHTML
                  hideBtn
                  key={i}
                  question={action?.name ?? ""}
                  answer={action?.text ?? ""}
                />
              ))}
            </div>

            <h3 className={cx("PlanTitle")}>
              {t("pageResult.plan.personal.title")}
            </h3>
            <h3 className={cx("PlanDescription")}>
              {t("pageResult.plan.personal.description")}
            </h3>

            <List className={cx("PlanNumberidList")} list={planList}/>
          </div>
        </section>
      )}

      {!survey.isPaid && (
        <section className={cx("Propose")}>
          <div className={cx("ProposeContainer", "container")}>
            <div className={cx("ProposeMain")}>
              {/*<div className={cx("ProposePrice")}>*/}
              {/*  <img src={require("assets/images/price_bg.svg")} alt=""/>*/}
              {/*  <p className={cx("ProposePriceValue", "old")}>$19.99</p>*/}
              {/*  <p className={cx("ProposePriceValue", "new")}>15$</p>*/}
              {/*</div>*/}

              <h3>{t("pageResult.propose.titleNew")}</h3>
              <p
                className={cx('ProposeMainDescription')}>{t("pageResult.propose.description", {model: survey.result?.name})}</p>
              <p className={cx('ProposeMainDescription', 'ProposeMainDescriptionTable')}>
                {t('strategyComplete')}
              </p>
              <div className={cx('Price')}>
                <div className={cx('PriceNew')}>{PRICE.new}{PRICE.currency}</div>
                <div className={cx('PriceOld')}>{t('simplePrice')} <span
                  className={cx('strike')}>{PRICE.old}</span>{PRICE.currency}</div>
              </div>
              <Button
                onClick={onClickCreateOrder}
                disabled={loadingCreateOrder}
                className={cx("ProposeBtn")}
              >
                {t("pageResult.propose.btn")}
              </Button>

              {errorCreateOrder && (
                <p className={cx("ProposeError")}>{errorCreateOrder}</p>
              )}
            </div>
            <List list={propseList} className={cx("ProposeList", 'ProposeTableHidden')}/>

          </div>
        </section>
      )}
      {!survey.isPaid && (
        <section className={cx('ProposeTablet')}>
          <div className={cx('container')}>
            <h2 className={cx('ProposeTabletTitle')}>{t('whatReceive')}</h2>
            <List list={propseList} className={cx("ProposeList")}/>
          </div>
        </section>
      )}
      <section className={cx("Final", role)}>
        <div className={cx("FinalContainer", "container")}>
          <div className={cx("FinalMain")}>
            <h3 className={cx("FinalTitle")}>{t("pageResult.final.title")}</h3>
            {survey.isPaid ? (
              <Fragment>
                {/*<p className={cx("FinalDescription")}>*/}
                {/*  {t("pageResult.final.description")}*/}
                {/*</p>*/}
                <Button
                  className={cx("FinalBtn")}
                  onClick={() =>
                    goWorkbook(language as LanguageEnum)
                    // window.location.assign("mailto:hello@personalbrandmodel.com")
                  }
                >
                  {t('More')}
                </Button>
              </Fragment>
            ) : (
              <Share className={cx("FinalShare")}>
                <p className={cx("FinalShareHashtag")}>
                  {t("pageResult.final.share.hashtag")}
                </p>
              </Share>
            )}
          </div>

          <div className={cx("FinalVideo")}>
            <div className={cx("IntroVideoOverlayBlock_r", "IntroVideoOverlayBlock_r" + role)}/>
            <div className={cx("IntroVideoOverlayBlock_l", "IntroVideoOverlayBlock_l" + role)}/>
            <div className={cx("IntroVideoOverlayBlock_t", "IntroVideoOverlayBlock_t" + role, "mobile")}/>
            <video autoPlay playsInline muted src={videoUrl}></video>
          </div>
        </div>
        {!survey.isPaid && (
          <Ticker
            className={cx("FinalTicker")}
            message={t("pageResult.ticker.slogan")}
          />
        )}
      </section>
    </div>
  );
};

export default PageResult;

import React, {FC} from 'react';
import classNames from "classnames/bind";
import s from './index.module.scss';
import Masonry from "react-masonry-css";
import {useTranslation} from "react-i18next";
import Button from "../../../components/Button";
import {ROUTES} from "../../../App";
import {useHistory} from "react-router-dom";
import Slider from '../components/Slider';

const cx = classNames.bind(s)
const isMobile = () => window.innerWidth <= 778;
type ActionProps = {}
const Action: FC<ActionProps> = () => {
  const [t] = useTranslation()
  const history = useHistory();
  const goToTest = () => history.push(ROUTES.test);
  const items = t('persons_message', {returnObjects: true}) as any
  return <section className={cx('Component')}>
    <div className={cx("container")}>
      <h2 className={cx('Title')}>{t('pageResult.ticker.slogan')}</h2>

      <div className={cx('Masonry')}>
        {!isMobile() && <Masonry
          breakpointCols={{
            default: 3,
            780: 2,
            500: 1
          }}
          className={cx("Grid")}
          columnClassName={cx("GridColumn")}>
          {items?.map((text: string, index: number) => <PersonItem goToTest={goToTest} t={t} key={index}
                                                                   number={index + 1}
                                                                   text={text}/>) ?? ''}
        </Masonry>}
        {isMobile() &&
        <Slider className={cx('SliderMobile')} slides={items?.map((text: string, index: number) => <PersonItem goToTest={goToTest} t={t} key={index}
                                                                                number={index + 1}
                                                                                text={text}/>) ?? ''}/>}
      </div>
      {isMobile() && <div className={cx('BtnWrap')}>
        <Button className={cx('Btn')}
                onClick={goToTest}>
          {t("sidebar.startTest")}</Button>}</div>}
    </div>
  </section>
}
export default Action

const PersonItem: FC<{
  number: number,
  text: string,
  t: any,
  goToTest: Function
}> = ({t, text, number, goToTest}) => {
  return <div className={cx('Person')}>
    <img src={require(`assets/images/action/avatar${number}.png`)} alt=""/>
    <div className={cx('PersonComment')}>{text}</div>
    {number === 5 && !isMobile() && <div className={cx('BtnWrap')}>
      <Button className={cx('Btn')}
              onClick={() => goToTest && goToTest()}>
        {t("sidebar.startTest")}</Button>
    </div>}
  </div>
}

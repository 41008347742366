import { PageTypeEnum, usePageQuery } from "api/generated";
import Preloader from "components/Preloader";
import { SidebarControl } from "components/Sidebar";
import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import PageError from "pages/Error";
import { useTranslation } from "react-i18next";
const cx = classNames.bind(styles);

const PageStatic = () => {
  const [_, { language: lang }] = useTranslation();
  const pageQuery = usePageQuery({
    variables: {
      pageType: PageTypeEnum.PrivacyPolicy,
      lang,
    },
  });

  if (pageQuery.loading) return <Preloader />;

  if (!pageQuery.data || pageQuery.error) return <PageError />;

  return (
    <div className={cx("Component")}>
      <div className="container">
        <header className={cx("Header")}>
          <h1 className={cx("Title")}>{pageQuery.data?.page?.title}</h1>
          <div>
            <SidebarControl />
          </div>
        </header>

        <main
          className={cx("Content")}
          dangerouslySetInnerHTML={{ __html: pageQuery.data?.page?.text ?? "" }}
        />
      </div>
    </div>
  );
};

export default PageStatic;

import {gql} from "@apollo/client";

export const PREVIOUS_ANSWER = gql`
    mutation PreviousAnswer($lang: String, $questionId: Int, $surveyUid: UUID) {
        previousAnswer(lang: $lang, questionId: $questionId, surveyUid: $surveyUid) {
            answer {
                id
                num
                survey {
                    id
                    questionsTotal
                    currentQuestion {
                        id
                        text
                        isReplyAsText
                        questionEnumType
                        isSocialNetworks

                        choices {
                            text
                        }
                    }
                }
            }
        }
    }

`
import {gql} from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

export type Query = {
  __typename?: 'Query';
  orderStatus?: Maybe<OrderType>;
  nextQuestion?: Maybe<QuestionType>;
  prevQuestion?: Maybe<QuestionType>;
  nextAnswer?: Maybe<AnswerType>;
  prevAnswer?: Maybe<AnswerType>;
  survey?: Maybe<SurveyType>;
  promoRoles?: Maybe<Array<Maybe<PromoNode>>>;
  promoSurveys?: Maybe<Array<Maybe<SurveyType>>>;
  post?: Maybe<PostType>;
  posts?: Maybe<BlogPageType>;
  settings?: Maybe<SettingsType>;
  finances?: Maybe<Array<Maybe<FinanceType>>>;
  page?: Maybe<PageType>;
  userInfo?: Maybe<UserNode>;
};


export type QueryOrderStatusArgs = {
  uid: Scalars['String'];
};


export type QueryNextQuestionArgs = {
  surveyUid?: Maybe<Scalars['UUID']>;
  questionId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
};


export type QueryPrevQuestionArgs = {
  surveyUid?: Maybe<Scalars['UUID']>;
  questionId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
};


export type QueryNextAnswerArgs = {
  surveyUid?: Maybe<Scalars['UUID']>;
  questionId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
};


export type QueryPrevAnswerArgs = {
  surveyUid?: Maybe<Scalars['UUID']>;
  questionId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
};


export type QuerySurveyArgs = {
  uid: Scalars['UUID'];
  lang?: Maybe<Scalars['String']>;
};


export type QueryPromoRolesArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryPromoSurveysArgs = {
  email: Scalars['String'];
};


export type QueryPostArgs = {
  slug: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryPostsArgs = {
  page: Scalars['Int'];
  lang: Scalars['String'];
};


export type QueryPageArgs = {
  pageType: PageTypeEnum;
  lang: Scalars['String'];
};

export type OrderType = {
  __typename?: 'OrderType';
  uid?: Maybe<Scalars['UUID']>;
  price?: Maybe<Scalars['Float']>;
  provider: OrderProvider;
  currency?: Maybe<OrderCurrency>;
  survey?: Maybe<SurveyType>;
  link?: Maybe<Scalars['String']>;
  status?: Maybe<OrderStatus>;
};


/** An enumeration. */
export enum OrderProvider {
  /** Fondy */
  Fondy = 'FONDY'
}

/** An enumeration. */
export enum OrderCurrency {
  /** UAH */
  Uah = 'UAH',
  /** RUB */
  Rub = 'RUB',
  /** USD */
  Usd = 'USD'
}

export type SurveyType = {
  __typename?: 'SurveyType';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  uid?: Maybe<Scalars['UUID']>;
  contactId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  googleToken?: Maybe<Scalars['String']>;
  youtubeLink?: Maybe<Scalars['String']>;
  facebookToken?: Maybe<Scalars['String']>;
  facebookLink?: Maybe<Scalars['String']>;
  instagramToken?: Maybe<Scalars['String']>;
  instagramLink?: Maybe<Scalars['String']>;
  tiktokLink?: Maybe<Scalars['String']>;
  telegramLink?: Maybe<Scalars['String']>;
  twitterLink?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
  isDone: Scalars['Boolean'];
  isFinished: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  currentAnswer?: Maybe<AnswerType>;
  additionalData?: Maybe<Scalars['String']>;
  ga?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['DateTime']>;
  customPrice?: Maybe<Scalars['Float']>;
  answers: Array<AnswerType>;
  orderSet: Array<OrderType>;
  orders: Array<OrderType>;
  initialRole?: Maybe<ComboType>;
  acquiredRole?: Maybe<ComboType>;
  /** percent answered */
  questionsAnswered?: Maybe<Scalars['Int']>;
  /** questions total */
  questionsTotal?: Maybe<Scalars['Int']>;
  result?: Maybe<ResultType>;
  currentQuestion?: Maybe<QuestionType>;
};


export type UserNode = {
  __typename?: 'UserNode';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  /** Required. 254 characters or fewer. Letters, digits and @/./+/-/_ only. */
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  website?: Maybe<Scalars['String']>;
  isEmailVerified: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
};


export type AnswerType = {
  __typename?: 'AnswerType';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  user?: Maybe<UserNode>;
  question?: Maybe<QuestionType>;
  text?: Maybe<Scalars['String']>;
  reply: Scalars['Boolean'];
  choice?: Maybe<ChoiceType>;
  survey?: Maybe<SurveyType>;
  isAnswered: Scalars['Boolean'];
  num?: Maybe<Scalars['Int']>;
  currentSurveys: Array<SurveyType>;
  choices?: Maybe<Array<Maybe<ChoiceType>>>;
};

export type QuestionType = {
  __typename?: 'QuestionType';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  text: Scalars['String'];
  isReplyAsText: Scalars['Boolean'];
  isSocialNetworks: Scalars['Boolean'];
  /** Определить порядок вопросов по очереди от 1 и далее */
  orderNumber?: Maybe<Scalars['Int']>;
  questionEnumType?: Maybe<TypeEnum>;
  choices?: Maybe<Array<Maybe<ChoiceType>>>;
  num?: Maybe<Scalars['Int']>;
  questionsTotal?: Maybe<Scalars['Int']>;
};

export enum TypeEnum {
  ExtroIntro = 'EXTRO_INTRO',
  Neurotism = 'NEUROTISM',
  Category = 'CATEGORY',
  Clarify = 'CLARIFY',
  PageTwoQuestions = 'PAGE_TWO_QUESTIONS',
  PageTwoClarify = 'PAGE_TWO_CLARIFY',
  SocialNetworks = 'SOCIAL_NETWORKS'
}

export type ChoiceType = {
  __typename?: 'ChoiceType';
  text?: Maybe<Scalars['String']>;
  question?: Maybe<QuestionType>;
};

export type ComboType = {
  __typename?: 'ComboType';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  role?: Maybe<RoleNode>;
  neurotism?: Maybe<ComboNeurotism>;
  extroversy?: Maybe<ComboExtroversy>;
  photo?: Maybe<Scalars['String']>;
  acquiredPhoto?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  resultShort?: Maybe<Scalars['String']>;
  resultShortRu?: Maybe<Scalars['String']>;
  resultShortUk?: Maybe<Scalars['String']>;
  resultShortEn?: Maybe<Scalars['String']>;
  resultLong?: Maybe<Scalars['String']>;
  resultLongRu?: Maybe<Scalars['String']>;
  resultLongUk?: Maybe<Scalars['String']>;
  resultLongEn?: Maybe<Scalars['String']>;
  properties: Array<PropertyType>;
  userContents: Array<UserContentType>;
  actionPlans: Array<ActionPlanType>;
  choices: Array<ChoiceType>;
  fullName?: Maybe<Scalars['String']>;
};

export type RoleNode = {
  __typename?: 'RoleNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  nameRu?: Maybe<Scalars['String']>;
  nameUk?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  roleType?: Maybe<RoleRoleType>;
  combos: Array<ComboType>;
  choiceSet: Array<ChoiceType>;
  questionSet: Array<QuestionType>;
};

/** An enumeration. */
export enum RoleRoleType {
  /** Marketer */
  Marketer = 'MARKETER',
  /** Insider */
  Insider = 'INSIDER',
  /** Expert */
  Expert = 'EXPERT',
  /** Creator */
  Creator = 'CREATOR',
  /** A-Lister */
  ALister = 'A_LISTER'
}

/** An enumeration. */
export enum ComboNeurotism {
  /** Low Neurotism */
  A_1 = 'A_1',
  /** High Neurotism */
  A_2 = 'A_2'
}

/** An enumeration. */
export enum ComboExtroversy {
  /** Introvert */
  A_1 = 'A_1',
  /** Extravert */
  A_2 = 'A_2'
}

export type PropertyType = {
  __typename?: 'PropertyType';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  color?: Maybe<PropertyColor>;
  text?: Maybe<Scalars['String']>;
  textRu?: Maybe<Scalars['String']>;
  textUk?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
  combo?: Maybe<ComboType>;
};

/** An enumeration. */
export enum PropertyColor {
  /** Lime */
  Lime = 'LIME',
  /** Purple */
  Purple = 'PURPLE',
  /** Pink */
  Pink = 'PINK'
}

export type UserContentType = {
  __typename?: 'UserContentType';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  color?: Maybe<UserContentColor>;
  name?: Maybe<Scalars['String']>;
  nameRu?: Maybe<Scalars['String']>;
  nameUk?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textRu?: Maybe<Scalars['String']>;
  textUk?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
  combo?: Maybe<ComboType>;
};

/** An enumeration. */
export enum UserContentColor {
  /** Lime */
  Lime = 'LIME',
  /** Purple */
  Purple = 'PURPLE',
  /** Pink */
  Pink = 'PINK'
}

export type ActionPlanType = {
  __typename?: 'ActionPlanType';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nameRu?: Maybe<Scalars['String']>;
  nameUk?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textRu?: Maybe<Scalars['String']>;
  textUk?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
  combo?: Maybe<ComboType>;
};

export type ResultType = {
  __typename?: 'ResultType';
  role?: Maybe<RoleEnum>;
  name?: Maybe<Scalars['String']>;
  head?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<PropertyType>>>;
  userContents?: Maybe<Array<Maybe<UserContentType>>>;
  acquiredPhoto?: Maybe<Scalars['String']>;
  acquiredName?: Maybe<Scalars['String']>;
  acquiredText?: Maybe<Scalars['String']>;
  actions?: Maybe<Array<Maybe<ActionPlanType>>>;
};

export enum RoleEnum {
  Marketer = 'MARKETER',
  Insider = 'INSIDER',
  Expert = 'EXPERT',
  Creator = 'CREATOR',
  ALister = 'A_LISTER'
}

/** An enumeration. */
export enum OrderStatus {
  /** Pending */
  Pending = 'PENDING',
  /** Success */
  Success = 'SUCCESS',
  /** Failure */
  Failure = 'FAILURE',
  /** Decline */
  Decline = 'DECLINE'
}

export type PromoNode = {
  __typename?: 'PromoNode';
  role?: Maybe<RoleNode>;
  combo?: Maybe<ComboType>;
  name?: Maybe<Scalars['String']>;
};

export type PostType = {
  __typename?: 'PostType';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  photo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleRu?: Maybe<Scalars['String']>;
  titleUk?: Maybe<Scalars['String']>;
  titleEn?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textRu?: Maybe<Scalars['String']>;
  textUk?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
  status?: Maybe<PostStatus>;
  /** shorted version of text attribute */
  shortly?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PostStatus {
  /** Draft */
  Draft = 'DRAFT',
  /** Publish */
  Publish = 'PUBLISH'
}

export type BlogPageType = {
  __typename?: 'BlogPageType';
  posts?: Maybe<Array<Maybe<PostType>>>;
  pageInfo?: Maybe<PageInfoType>;
};

export type PageInfoType = {
  __typename?: 'PageInfoType';
  postsTotal?: Maybe<Scalars['Int']>;
  postsPerPage?: Maybe<Scalars['Int']>;
};

export type SettingsType = {
  __typename?: 'SettingsType';
  postsPerPage: Scalars['Int'];
  signature?: Maybe<Scalars['String']>;
};

export type FinanceType = {
  __typename?: 'FinanceType';
  currency?: Maybe<FinanceCurrency>;
  price?: Maybe<Scalars['Float']>;
  provider?: Maybe<FinanceProvider>;
};

/** An enumeration. */
export enum FinanceCurrency {
  /** UAH */
  Uah = 'UAH',
  /** RUB */
  Rub = 'RUB',
  /** USD */
  Usd = 'USD'
}

/** An enumeration. */
export enum FinanceProvider {
  /** Fondy */
  Fondy = 'FONDY'
}

export type PageType = {
  __typename?: 'PageType';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  id: Scalars['ID'];
  pageType?: Maybe<PagePageType>;
  title?: Maybe<Scalars['String']>;
  titleRu?: Maybe<Scalars['String']>;
  titleUk?: Maybe<Scalars['String']>;
  titleEn?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textRu?: Maybe<Scalars['String']>;
  textUk?: Maybe<Scalars['String']>;
  textEn?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PagePageType {
  /** Privacy Policy */
  PrivacyPolicy = 'PRIVACY_POLICY',
  /** Terms of Service */
  TermsOfService = 'TERMS_OF_SERVICE'
}

export enum PageTypeEnum {
  PrivacyPolicy = 'PRIVACY_POLICY',
  TermsOfService = 'TERMS_OF_SERVICE'
}

export type Mutation = {
  __typename?: 'Mutation';
  createOrder?: Maybe<CreateOrder>;
  createPromoOrder?: Maybe<CreatePromoOrder>;
  startSurvey?: Maybe<StartSurvey>;
  /**
   * At least one token / link must be provided in order to successfully
   * complete
   */
  socialNetworks?: Maybe<SocialNetworks>;
  finishSurvey?: Maybe<FinishSurvey>;
  setAnswer?: Maybe<SetAnswer>;
  previousAnswer?: Maybe<PrevAnswer>;
  nextAnswer?: Maybe<NextAnswer>;
  nextQuestion?: Maybe<NextQuestion>;
  previousQuestion?: Maybe<PrevQuestion>;
  signup?: Maybe<SignUp>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
};


export type MutationCreateOrderArgs = {
  currency: CurrencyEnum;
  language?: Maybe<LanguageEnum>;
  provider: ProviderEnum;
  suid: Scalars['UUID'];
};


export type MutationCreatePromoOrderArgs = {
  currency: CurrencyEnum;
  lang: Scalars['String'];
  provider: ProviderEnum;
  uids: Array<Maybe<Scalars['UUID']>>;
};


export type MutationStartSurveyArgs = {
  additionalData?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  facebookToken?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  ga?: Maybe<Scalars['String']>;
  googleToken?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
};


export type MutationSocialNetworksArgs = {
  facebookLink?: Maybe<Scalars['String']>;
  facebookToken?: Maybe<Scalars['String']>;
  googleToken?: Maybe<Scalars['String']>;
  instagramLink?: Maybe<Scalars['String']>;
  instagramToken?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  suid: Scalars['UUID'];
  telegramLink?: Maybe<Scalars['String']>;
  tiktokLink?: Maybe<Scalars['String']>;
  twitterLink?: Maybe<Scalars['String']>;
  youtubeLink?: Maybe<Scalars['String']>;
};


export type MutationFinishSurveyArgs = {
  additionalData?: Maybe<Scalars['String']>;
  age: Scalars['Int'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  ga?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  suid: Scalars['UUID'];
};


export type MutationSetAnswerArgs = {
  lang?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['Int']>;
  suid?: Maybe<Scalars['UUID']>;
  text?: Maybe<Scalars['String']>;
  yesno?: Maybe<Scalars['Boolean']>;
};


export type MutationPreviousAnswerArgs = {
  lang?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['Int']>;
  surveyUid?: Maybe<Scalars['UUID']>;
};


export type MutationNextAnswerArgs = {
  lang?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['Int']>;
  surveyUid?: Maybe<Scalars['UUID']>;
};


export type MutationNextQuestionArgs = {
  lang?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['Int']>;
  surveyUid?: Maybe<Scalars['UUID']>;
};


export type MutationPreviousQuestionArgs = {
  lang?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['Int']>;
  surveyUid?: Maybe<Scalars['UUID']>;
};


export type MutationSignupArgs = {
  user?: Maybe<UserInput>;
};


export type MutationTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

export type CreateOrder = {
  __typename?: 'CreateOrder';
  order?: Maybe<OrderType>;
  errors?: Maybe<Scalars['String']>;
};

export enum CurrencyEnum {
  Uah = 'UAH',
  Rub = 'RUB',
  Usd = 'USD'
}

export enum LanguageEnum {
  Uk = 'UK',
  Ru = 'RU',
  En = 'EN'
}

export enum ProviderEnum {
  Fondy = 'FONDY'
}

export type CreatePromoOrder = {
  __typename?: 'CreatePromoOrder';
  order?: Maybe<OrderType>;
  errors?: Maybe<Scalars['String']>;
};

export type StartSurvey = {
  __typename?: 'StartSurvey';
  survey?: Maybe<SurveyType>;
};

/**
 * At least one token / link must be provided in order to successfully
 * complete
 */
export type SocialNetworks = {
  __typename?: 'SocialNetworks';
  nextQuestion?: Maybe<QuestionType>;
  answer?: Maybe<AnswerType>;
};

export type FinishSurvey = {
  __typename?: 'FinishSurvey';
  survey?: Maybe<SurveyType>;
};

export type SetAnswer = {
  __typename?: 'SetAnswer';
  nextQuestion?: Maybe<QuestionType>;
  answer?: Maybe<AnswerType>;
};

export type PrevAnswer = {
  __typename?: 'PrevAnswer';
  answer?: Maybe<AnswerType>;
};

export type NextAnswer = {
  __typename?: 'NextAnswer';
  answer?: Maybe<AnswerType>;
};

export type NextQuestion = {
  __typename?: 'NextQuestion';
  question?: Maybe<QuestionType>;
};

export type PrevQuestion = {
  __typename?: 'PrevQuestion';
  question?: Maybe<QuestionType>;
};

export type SignUp = {
  __typename?: 'SignUp';
  user?: Maybe<UserNode>;
};

export type UserInput = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};


export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type QuestionFragment = (
  { __typename?: 'QuestionType' }
  & Pick<QuestionType, 'id' | 'text' | 'isReplyAsText' | 'questionEnumType' | 'isSocialNetworks'>
  & {
  choices?: Maybe<Array<Maybe<(
    { __typename?: 'ChoiceType' }
    & Pick<ChoiceType, 'text'>
    )>>>
}
  );

export type CreateOrdeMutationVariables = Exact<{
  currency: CurrencyEnum;
  language?: Maybe<LanguageEnum>;
  provider: ProviderEnum;
  suid: Scalars['UUID'];
}>;


export type CreateOrdeMutation = (
  { __typename?: 'Mutation' }
  & {
  createOrder?: Maybe<(
    { __typename?: 'CreateOrder' }
    & Pick<CreateOrder, 'errors'>
    & {
    order?: Maybe<(
      { __typename?: 'OrderType' }
      & Pick<OrderType, 'link' | 'uid'>
      )>
  }
    )>
}
  );

export type CreatePromoOrderMutationVariables = Exact<{
  currency: CurrencyEnum;
  lang: Scalars['String'];
  provider: ProviderEnum;
  uids: Array<Maybe<Scalars['UUID']>>;
}>;


export type CreatePromoOrderMutation = (
  { __typename?: 'Mutation' }
  & {
  createPromoOrder?: Maybe<(
    { __typename?: 'CreatePromoOrder' }
    & Pick<CreatePromoOrder, 'errors'>
    & {
    order?: Maybe<(
      { __typename?: 'OrderType' }
      & Pick<OrderType, 'link' | 'status'>
      )>
  }
    )>
}
  );

export type FinishSurveyMutationVariables = Exact<{
  additionalData?: Maybe<Scalars['String']>;
  age: Scalars['Int'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  suid: Scalars['UUID'];
  ga?: Maybe<Scalars['String']>;
}>;


export type FinishSurveyMutation = (
  { __typename?: 'Mutation' }
  & {
  finishSurvey?: Maybe<(
    { __typename?: 'FinishSurvey' }
    & {
    survey?: Maybe<(
      { __typename?: 'SurveyType' }
      & Pick<SurveyType, 'id' | 'isDone' | 'isFinished'>
      )>
  }
    )>
}
  );

export type SetAnswerMutationVariables = Exact<{
  questionId?: Maybe<Scalars['Int']>;
  suid?: Maybe<Scalars['UUID']>;
  text?: Maybe<Scalars['String']>;
  yesno?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type SetAnswerMutation = (
  { __typename?: 'Mutation' }
  & {
  setAnswer?: Maybe<(
    { __typename?: 'SetAnswer' }
    & {
    answer?: Maybe<(
      { __typename?: 'AnswerType' }
      & Pick<AnswerType, 'id' | 'num'>
      & {
      choices?: Maybe<Array<Maybe<(
        { __typename?: 'ChoiceType' }
        & Pick<ChoiceType, 'text'>
        )>>>, question?: Maybe<(
        { __typename?: 'QuestionType' }
        & QuestionFragment
        )>, survey?: Maybe<(
        { __typename?: 'SurveyType' }
        & Pick<SurveyType, 'id' | 'questionsTotal'>
        & {
        currentQuestion?: Maybe<(
          { __typename?: 'QuestionType' }
          & QuestionFragment
          )>
      }
        )>
    }
      )>
  }
    )>
}
  );

export type SocialNetworksMutationVariables = Exact<{
  facebookLink?: Maybe<Scalars['String']>;
  facebookToken?: Maybe<Scalars['String']>;
  googleToken?: Maybe<Scalars['String']>;
  instagramLink?: Maybe<Scalars['String']>;
  instagramToken?: Maybe<Scalars['String']>;
  suid: Scalars['UUID'];
  telegramLink?: Maybe<Scalars['String']>;
  tiktokLink?: Maybe<Scalars['String']>;
  twitterLink?: Maybe<Scalars['String']>;
  youtubeLink?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type SocialNetworksMutation = (
  { __typename?: 'Mutation' }
  & {
  socialNetworks?: Maybe<(
    { __typename?: 'SocialNetworks' }
    & {
    answer?: Maybe<(
      { __typename?: 'AnswerType' }
      & Pick<AnswerType, 'id' | 'num'>
      & {
      survey?: Maybe<(
        { __typename?: 'SurveyType' }
        & Pick<SurveyType, 'id' | 'questionsTotal'>
        & {
        currentQuestion?: Maybe<(
          { __typename?: 'QuestionType' }
          & QuestionFragment
          )>
      }
        )>
    }
      )>
  }
    )>
}
  );

export type StartSurveyMutationVariables = Exact<{
  email: Scalars['String'];
  firstName: Scalars['String'];
  facebookToken?: Maybe<Scalars['String']>;
  googleToken?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  additionalData?: Maybe<Scalars['String']>;
  ga?: Maybe<Scalars['String']>;
}>;


export type StartSurveyMutation = (
  { __typename?: 'Mutation' }
  & {
  startSurvey?: Maybe<(
    { __typename?: 'StartSurvey' }
    & {
    survey?: Maybe<(
      { __typename?: 'SurveyType' }
      & Pick<SurveyType, 'id' | 'uid' | 'isDone' | 'isFinished' | 'questionsTotal'>
      & {
      currentAnswer?: Maybe<(
        { __typename?: 'AnswerType' }
        & Pick<AnswerType, 'id' | 'num'>
        )>, currentQuestion?: Maybe<(
        { __typename?: 'QuestionType' }
        & QuestionFragment
        )>
    }
      )>
  }
    )>
}
  );

export type PageQueryVariables = Exact<{
  pageType: PageTypeEnum;
  lang: Scalars['String'];
}>;


export type PageQuery = (
  { __typename?: 'Query' }
  & {
  page?: Maybe<(
    { __typename?: 'PageType' }
    & Pick<PageType, 'id' | 'title' | 'text'>
    )>
}
  );

export type PostQueryVariables = Exact<{
  slug: Scalars['String'];
  lang: Scalars['String'];
}>;


export type PostQuery = (
  { __typename?: 'Query' }
  & {
  post?: Maybe<(
    { __typename?: 'PostType' }
    & Pick<PostType, 'id' | 'photo' | 'slug' | 'title' | 'text'>
    )>
}
  );

export type PostsQueryVariables = Exact<{
  page: Scalars['Int'];
  lang: Scalars['String'];
}>;


export type PostsQuery = (
  { __typename?: 'Query' }
  & {
  posts?: Maybe<(
    { __typename?: 'BlogPageType' }
    & {
    pageInfo?: Maybe<(
      { __typename?: 'PageInfoType' }
      & Pick<PageInfoType, 'postsTotal' | 'postsPerPage'>
      )>, posts?: Maybe<Array<Maybe<(
      { __typename?: 'PostType' }
      & Pick<PostType, 'id' | 'photo' | 'slug' | 'title' | 'shortly'>
      )>>>
  }
    )>
}
  );

export type PrevAnswerQueryVariables = Exact<{
  surveyUid?: Maybe<Scalars['UUID']>;
  questionId?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type PrevAnswerQuery = (
  { __typename?: 'Query' }
  & {
  prevAnswer?: Maybe<(
    { __typename?: 'AnswerType' }
    & Pick<AnswerType, 'id' | 'num'>
    & {
    survey?: Maybe<(
      { __typename?: 'SurveyType' }
      & Pick<SurveyType, 'id' | 'questionsTotal'>
      & {
      currentQuestion?: Maybe<(
        { __typename?: 'QuestionType' }
        & QuestionFragment
        )>
    }
      )>
  }
    )>
}
  );

export type PromoRolesQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;


export type PromoRolesQuery = (
  { __typename?: 'Query' }
  & {
  promoRoles?: Maybe<Array<Maybe<(
    { __typename?: 'PromoNode' }
    & Pick<PromoNode, 'name'>
    & {
    role?: Maybe<(
      { __typename?: 'RoleNode' }
      & Pick<RoleNode, 'name' | 'id'>
      )>, combo?: Maybe<(
      { __typename?: 'ComboType' }
      & Pick<ComboType, 'name' | 'id'>
      )>
  }
    )>>>
}
  );

export type PromoSurveysQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type PromoSurveysQuery = (
  { __typename?: 'Query' }
  & {
  promoSurveys?: Maybe<Array<Maybe<(
    { __typename?: 'SurveyType' }
    & Pick<SurveyType, 'uid'>
    & {
    result?: Maybe<(
      { __typename?: 'ResultType' }
      & Pick<ResultType, 'name'>
      )>
  }
    )>>>
}
  );

export type SurveyQueryVariables = Exact<{
  uid: Scalars['UUID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type SurveyQuery = (
  { __typename?: 'Query' }
  & {
  survey?: Maybe<(
    { __typename?: 'SurveyType' }
    & Pick<SurveyType, 'id' | 'uid' | 'isDone' | 'isFinished' | 'facebookToken' | 'googleToken' | 'firstName' | 'email' | 'questionsTotal'>
    & {
    currentAnswer?: Maybe<(
      { __typename?: 'AnswerType' }
      & Pick<AnswerType, 'id' | 'num'>
      )>, currentQuestion?: Maybe<(
      { __typename?: 'QuestionType' }
      & QuestionFragment
      )>
  }
    )>
}
  );

export type SurveyResultQueryVariables = Exact<{
  uid: Scalars['UUID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type SurveyResultQuery = (
  { __typename?: 'Query' }
  & {
  survey?: Maybe<(
    { __typename?: 'SurveyType' }
    & Pick<SurveyType, 'isFinished' | 'isPaid'>
    & {
    result?: Maybe<(
      { __typename?: 'ResultType' }
      & Pick<ResultType, 'role' | 'name' | 'head' | 'description' | 'acquiredPhoto' | 'acquiredText' | 'acquiredName'>
      & {
      properties?: Maybe<Array<Maybe<(
        { __typename?: 'PropertyType' }
        & Pick<PropertyType, 'order' | 'color' | 'text'>
        )>>>, userContents?: Maybe<Array<Maybe<(
        { __typename?: 'UserContentType' }
        & Pick<UserContentType, 'order' | 'color' | 'name' | 'text'>
        )>>>, actions?: Maybe<Array<Maybe<(
        { __typename?: 'ActionPlanType' }
        & Pick<ActionPlanType, 'order' | 'name' | 'text'>
        )>>>
    }
      )>
  }
    )>
}
  );

export const QuestionFragmentDoc = gql`
    fragment Question on QuestionType {
        id
        text
        isReplyAsText
        questionEnumType
        isSocialNetworks
        choices {
            text
        }
    }
`;
export const CreateOrdeDocument = gql`
    mutation CreateOrde($currency: CurrencyEnum!, $language: LanguageEnum, $provider: ProviderEnum!, $suid: UUID!) {
        createOrder(currency: $currency, language: $language, provider: $provider, suid: $suid) {
            order {
                link
                uid
            }
            errors
        }
    }
`;
export type CreateOrdeMutationFn = Apollo.MutationFunction<CreateOrdeMutation, CreateOrdeMutationVariables>;

/**
 * __useCreateOrdeMutation__
 *
 * To run a mutation, you first call `useCreateOrdeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrdeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrdeMutation, { data, loading, error }] = useCreateOrdeMutation({
 *   variables: {
 *      currency: // value for 'currency'
 *      language: // value for 'language'
 *      provider: // value for 'provider'
 *      suid: // value for 'suid'
 *   },
 * });
 */
export function useCreateOrdeMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrdeMutation, CreateOrdeMutationVariables>) {
  return Apollo.useMutation<CreateOrdeMutation, CreateOrdeMutationVariables>(CreateOrdeDocument, baseOptions);
}

export type CreateOrdeMutationHookResult = ReturnType<typeof useCreateOrdeMutation>;
export type CreateOrdeMutationResult = Apollo.MutationResult<CreateOrdeMutation>;
export type CreateOrdeMutationOptions = Apollo.BaseMutationOptions<CreateOrdeMutation, CreateOrdeMutationVariables>;
export const CreatePromoOrderDocument = gql`
    mutation CreatePromoOrder($currency: CurrencyEnum!, $lang: String!, $provider: ProviderEnum!, $uids: [UUID]!) {
        createPromoOrder(currency: $currency, lang: $lang, provider: $provider, uids: $uids) {
            order {
                link
                status
            }
            errors
        }
    }
`;
export type CreatePromoOrderMutationFn = Apollo.MutationFunction<CreatePromoOrderMutation, CreatePromoOrderMutationVariables>;

/**
 * __useCreatePromoOrderMutation__
 *
 * To run a mutation, you first call `useCreatePromoOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoOrderMutation, { data, loading, error }] = useCreatePromoOrderMutation({
 *   variables: {
 *      currency: // value for 'currency'
 *      lang: // value for 'lang'
 *      provider: // value for 'provider'
 *      uids: // value for 'uids'
 *   },
 * });
 */
export function useCreatePromoOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromoOrderMutation, CreatePromoOrderMutationVariables>) {
  return Apollo.useMutation<CreatePromoOrderMutation, CreatePromoOrderMutationVariables>(CreatePromoOrderDocument, baseOptions);
}

export type CreatePromoOrderMutationHookResult = ReturnType<typeof useCreatePromoOrderMutation>;
export type CreatePromoOrderMutationResult = Apollo.MutationResult<CreatePromoOrderMutation>;
export type CreatePromoOrderMutationOptions = Apollo.BaseMutationOptions<CreatePromoOrderMutation, CreatePromoOrderMutationVariables>;
export const FinishSurveyDocument = gql`
    mutation FinishSurvey($additionalData: String, $age: Int!, $email: String!, $firstName: String!, $lang: String, $lastName: String!, $suid: UUID!, $ga: String) {
        finishSurvey(additionalData: $additionalData, age: $age, email: $email, firstName: $firstName, lang: $lang, lastName: $lastName, suid: $suid, ga: $ga) {
            survey {
                id
                isDone
                isFinished
            }
        }
    }
`;
export type FinishSurveyMutationFn = Apollo.MutationFunction<FinishSurveyMutation, FinishSurveyMutationVariables>;

/**
 * __useFinishSurveyMutation__
 *
 * To run a mutation, you first call `useFinishSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishSurveyMutation, { data, loading, error }] = useFinishSurveyMutation({
 *   variables: {
 *      additionalData: // value for 'additionalData'
 *      age: // value for 'age'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lang: // value for 'lang'
 *      lastName: // value for 'lastName'
 *      suid: // value for 'suid'
 *      ga: // value for 'ga'
 *   },
 * });
 */
export function useFinishSurveyMutation(baseOptions?: Apollo.MutationHookOptions<FinishSurveyMutation, FinishSurveyMutationVariables>) {
  return Apollo.useMutation<FinishSurveyMutation, FinishSurveyMutationVariables>(FinishSurveyDocument, baseOptions);
}

export type FinishSurveyMutationHookResult = ReturnType<typeof useFinishSurveyMutation>;
export type FinishSurveyMutationResult = Apollo.MutationResult<FinishSurveyMutation>;
export type FinishSurveyMutationOptions = Apollo.BaseMutationOptions<FinishSurveyMutation, FinishSurveyMutationVariables>;
export const SetAnswerDocument = gql`
    mutation SetAnswer($questionId: Int, $suid: UUID, $text: String, $yesno: Boolean, $lang: String) {
        setAnswer(questionId: $questionId, suid: $suid, text: $text, yesno: $yesno, lang: $lang) {
            answer {
                id
                num
                choices {
                    text
                }
                question {
                    ...Question
                }
                survey {
                    id
                    questionsTotal
                    currentQuestion {
                        ...Question
                    }
                }
            }
        }
    }
${QuestionFragmentDoc}`;
export type SetAnswerMutationFn = Apollo.MutationFunction<SetAnswerMutation, SetAnswerMutationVariables>;

/**
 * __useSetAnswerMutation__
 *
 * To run a mutation, you first call `useSetAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAnswerMutation, { data, loading, error }] = useSetAnswerMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      suid: // value for 'suid'
 *      text: // value for 'text'
 *      yesno: // value for 'yesno'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSetAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SetAnswerMutation, SetAnswerMutationVariables>) {
  return Apollo.useMutation<SetAnswerMutation, SetAnswerMutationVariables>(SetAnswerDocument, baseOptions);
}

export type SetAnswerMutationHookResult = ReturnType<typeof useSetAnswerMutation>;
export type SetAnswerMutationResult = Apollo.MutationResult<SetAnswerMutation>;
export type SetAnswerMutationOptions = Apollo.BaseMutationOptions<SetAnswerMutation, SetAnswerMutationVariables>;
export const SocialNetworksDocument = gql`
    mutation SocialNetworks($facebookLink: String, $facebookToken: String, $googleToken: String, $instagramLink: String, $instagramToken: String, $suid: UUID!, $telegramLink: String, $tiktokLink: String, $twitterLink: String, $youtubeLink: String, $lang: String) {
        socialNetworks(facebookLink: $facebookLink, facebookToken: $facebookToken, googleToken: $googleToken, instagramLink: $instagramLink, instagramToken: $instagramToken, suid: $suid, telegramLink: $telegramLink, tiktokLink: $tiktokLink, twitterLink: $twitterLink, youtubeLink: $youtubeLink, lang: $lang) {
            answer {
                id
                num
                survey {
                    id
                    questionsTotal
                    currentQuestion {
                        ...Question
                    }
                }
            }
        }
    }
${QuestionFragmentDoc}`;
export type SocialNetworksMutationFn = Apollo.MutationFunction<SocialNetworksMutation, SocialNetworksMutationVariables>;

/**
 * __useSocialNetworksMutation__
 *
 * To run a mutation, you first call `useSocialNetworksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialNetworksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialNetworksMutation, { data, loading, error }] = useSocialNetworksMutation({
 *   variables: {
 *      facebookLink: // value for 'facebookLink'
 *      facebookToken: // value for 'facebookToken'
 *      googleToken: // value for 'googleToken'
 *      instagramLink: // value for 'instagramLink'
 *      instagramToken: // value for 'instagramToken'
 *      suid: // value for 'suid'
 *      telegramLink: // value for 'telegramLink'
 *      tiktokLink: // value for 'tiktokLink'
 *      twitterLink: // value for 'twitterLink'
 *      youtubeLink: // value for 'youtubeLink'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSocialNetworksMutation(baseOptions?: Apollo.MutationHookOptions<SocialNetworksMutation, SocialNetworksMutationVariables>) {
  return Apollo.useMutation<SocialNetworksMutation, SocialNetworksMutationVariables>(SocialNetworksDocument, baseOptions);
}

export type SocialNetworksMutationHookResult = ReturnType<typeof useSocialNetworksMutation>;
export type SocialNetworksMutationResult = Apollo.MutationResult<SocialNetworksMutation>;
export type SocialNetworksMutationOptions = Apollo.BaseMutationOptions<SocialNetworksMutation, SocialNetworksMutationVariables>;
export const StartSurveyDocument = gql`
    mutation StartSurvey($email: String!, $firstName: String!, $facebookToken: String, $googleToken: String, $lang: String, $code: String, $additionalData: String, $ga: String) {
        startSurvey(additionalData: $additionalData, email: $email, firstName: $firstName, facebookToken: $facebookToken, googleToken: $googleToken, lang: $lang, code: $code, ga: $ga) {
            survey {
                id
                uid
                isDone
                isFinished
                currentAnswer {
                    id
                    num
                }
                questionsTotal
                currentQuestion {
                    ...Question
                }
            }
        }
    }
${QuestionFragmentDoc}`;
export type StartSurveyMutationFn = Apollo.MutationFunction<StartSurveyMutation, StartSurveyMutationVariables>;

/**
 * __useStartSurveyMutation__
 *
 * To run a mutation, you first call `useStartSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSurveyMutation, { data, loading, error }] = useStartSurveyMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      facebookToken: // value for 'facebookToken'
 *      googleToken: // value for 'googleToken'
 *      lang: // value for 'lang'
 *      code: // value for 'code'
 *      additionalData: // value for 'additionalData'
 *      ga: // value for 'ga'
 *   },
 * });
 */
export function useStartSurveyMutation(baseOptions?: Apollo.MutationHookOptions<StartSurveyMutation, StartSurveyMutationVariables>) {
  return Apollo.useMutation<StartSurveyMutation, StartSurveyMutationVariables>(StartSurveyDocument, baseOptions);
}

export type StartSurveyMutationHookResult = ReturnType<typeof useStartSurveyMutation>;
export type StartSurveyMutationResult = Apollo.MutationResult<StartSurveyMutation>;
export type StartSurveyMutationOptions = Apollo.BaseMutationOptions<StartSurveyMutation, StartSurveyMutationVariables>;
export const PageDocument = gql`
    query Page($pageType: PageTypeEnum!, $lang: String!) {
        page(pageType: $pageType, lang: $lang) {
            id
            title
            text
        }
    }
`;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      pageType: // value for 'pageType'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePageQuery(baseOptions?: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
  return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, baseOptions);
}

export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
  return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, baseOptions);
}

export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>;
export const PostDocument = gql`
    query Post($slug: String!, $lang: String!) {
        post(slug: $slug, lang: $lang) {
            id
            photo
            slug
            title
            text
        }
    }
`;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePostQuery(baseOptions?: Apollo.QueryHookOptions<PostQuery, PostQueryVariables>) {
  return Apollo.useQuery<PostQuery, PostQueryVariables>(PostDocument, baseOptions);
}

export function usePostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostQuery, PostQueryVariables>) {
  return Apollo.useLazyQuery<PostQuery, PostQueryVariables>(PostDocument, baseOptions);
}

export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>;
export type PostQueryResult = Apollo.QueryResult<PostQuery, PostQueryVariables>;
export const PostsDocument = gql`
    query Posts($page: Int!, $lang: String!) {
        posts(page: $page, lang: $lang) {
            pageInfo {
                postsTotal
                postsPerPage
            }
            posts {
                id
                photo
                slug
                title
                shortly
            }
        }
    }
`;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePostsQuery(baseOptions?: Apollo.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
  return Apollo.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, baseOptions);
}

export function usePostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
  return Apollo.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, baseOptions);
}

export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = Apollo.QueryResult<PostsQuery, PostsQueryVariables>;
export const PrevAnswerDocument = gql`
    query PrevAnswer($surveyUid: UUID, $questionId: Int, $lang: String) {
        prevAnswer(surveyUid: $surveyUid, questionId: $questionId, lang: $lang) {
            id
            num
            survey {
                id
                questionsTotal
                currentQuestion {
                    ...Question
                }
            }
        }
    }
${QuestionFragmentDoc}`;

/**
 * __usePrevAnswerQuery__
 *
 * To run a query within a React component, call `usePrevAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrevAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrevAnswerQuery({
 *   variables: {
 *      surveyUid: // value for 'surveyUid'
 *      questionId: // value for 'questionId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function usePrevAnswerQuery(baseOptions?: Apollo.QueryHookOptions<PrevAnswerQuery, PrevAnswerQueryVariables>) {
  return Apollo.useQuery<PrevAnswerQuery, PrevAnswerQueryVariables>(PrevAnswerDocument, baseOptions);
}

export function usePrevAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrevAnswerQuery, PrevAnswerQueryVariables>) {
  return Apollo.useLazyQuery<PrevAnswerQuery, PrevAnswerQueryVariables>(PrevAnswerDocument, baseOptions);
}

export type PrevAnswerQueryHookResult = ReturnType<typeof usePrevAnswerQuery>;
export type PrevAnswerLazyQueryHookResult = ReturnType<typeof usePrevAnswerLazyQuery>;
export type PrevAnswerQueryResult = Apollo.QueryResult<PrevAnswerQuery, PrevAnswerQueryVariables>;
export const PromoRolesDocument = gql`
    query PromoRoles($email: String) {
        promoRoles(email: $email) {
            name
            role {
                name
                id
            }
            combo {
                name
                id
            }
        }
    }
`;

/**
 * __usePromoRolesQuery__
 *
 * To run a query within a React component, call `usePromoRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoRolesQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePromoRolesQuery(baseOptions?: Apollo.QueryHookOptions<PromoRolesQuery, PromoRolesQueryVariables>) {
  return Apollo.useQuery<PromoRolesQuery, PromoRolesQueryVariables>(PromoRolesDocument, baseOptions);
}

export function usePromoRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoRolesQuery, PromoRolesQueryVariables>) {
  return Apollo.useLazyQuery<PromoRolesQuery, PromoRolesQueryVariables>(PromoRolesDocument, baseOptions);
}

export type PromoRolesQueryHookResult = ReturnType<typeof usePromoRolesQuery>;
export type PromoRolesLazyQueryHookResult = ReturnType<typeof usePromoRolesLazyQuery>;
export type PromoRolesQueryResult = Apollo.QueryResult<PromoRolesQuery, PromoRolesQueryVariables>;
export const PromoSurveysDocument = gql`
    query PromoSurveys($email: String!) {
        promoSurveys(email: $email) {
            uid
            result {
                name
            }
        }
    }
`;

/**
 * __usePromoSurveysQuery__
 *
 * To run a query within a React component, call `usePromoSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoSurveysQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePromoSurveysQuery(baseOptions?: Apollo.QueryHookOptions<PromoSurveysQuery, PromoSurveysQueryVariables>) {
  return Apollo.useQuery<PromoSurveysQuery, PromoSurveysQueryVariables>(PromoSurveysDocument, baseOptions);
}

export function usePromoSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoSurveysQuery, PromoSurveysQueryVariables>) {
  return Apollo.useLazyQuery<PromoSurveysQuery, PromoSurveysQueryVariables>(PromoSurveysDocument, baseOptions);
}

export type PromoSurveysQueryHookResult = ReturnType<typeof usePromoSurveysQuery>;
export type PromoSurveysLazyQueryHookResult = ReturnType<typeof usePromoSurveysLazyQuery>;
export type PromoSurveysQueryResult = Apollo.QueryResult<PromoSurveysQuery, PromoSurveysQueryVariables>;
export const SurveyDocument = gql`
    query Survey($uid: UUID!, $lang: String) {
        survey(uid: $uid, lang: $lang) {
            id
            uid
            isDone
            isFinished
            facebookToken
            googleToken
            firstName
            email
            questionsTotal
            currentAnswer {
                id
                num
            }
            currentQuestion {
                ...Question
            }
        }
    }
${QuestionFragmentDoc}`;

/**
 * __useSurveyQuery__
 *
 * To run a query within a React component, call `useSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSurveyQuery(baseOptions?: Apollo.QueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
  return Apollo.useQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, baseOptions);
}

export function useSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
  return Apollo.useLazyQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, baseOptions);
}

export type SurveyQueryHookResult = ReturnType<typeof useSurveyQuery>;
export type SurveyLazyQueryHookResult = ReturnType<typeof useSurveyLazyQuery>;
export type SurveyQueryResult = Apollo.QueryResult<SurveyQuery, SurveyQueryVariables>;
export const SurveyResultDocument = gql`
    query SurveyResult($uid: UUID!, $lang: String) {
        survey(uid: $uid, lang: $lang) {
            isFinished
            isPaid
            result {
                role
                name
                head
                description
                acquiredPhoto
                acquiredText
                acquiredName
                properties {
                    order
                    color
                    text
                }
                userContents {
                    order
                    color
                    name
                    text
                }
                actions {
                    order
                    name
                    text
                }
            }
        }
    }
`;

/**
 * __useSurveyResultQuery__
 *
 * To run a query within a React component, call `useSurveyResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyResultQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSurveyResultQuery(baseOptions?: Apollo.QueryHookOptions<SurveyResultQuery, SurveyResultQueryVariables>) {
  return Apollo.useQuery<SurveyResultQuery, SurveyResultQueryVariables>(SurveyResultDocument, baseOptions);
}

export function useSurveyResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyResultQuery, SurveyResultQueryVariables>) {
  return Apollo.useLazyQuery<SurveyResultQuery, SurveyResultQueryVariables>(SurveyResultDocument, baseOptions);
}

export type SurveyResultQueryHookResult = ReturnType<typeof useSurveyResultQuery>;
export type SurveyResultLazyQueryHookResult = ReturnType<typeof useSurveyResultLazyQuery>;
export type SurveyResultQueryResult = Apollo.QueryResult<SurveyResultQuery, SurveyResultQueryVariables>;

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}

const result: IntrospectionResultData = {
  "__schema": {
    "types": []
  }
};
export default result;
    
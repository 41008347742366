export default {
  ru: 'Рус',
  uk: 'Укр',
  en: 'Анг',
  'More': 'Узнать',

  'Your workbook': 'Твоя книга бренда',
  moreInfo: 'Больше',
  authorTitle: 'Авторы теста на определение модели личности:',
  orderAStrategy: 'Заказать стратегию',
  personalityBrandStrategy: `Стратегия бренда личности {{price}}`,
  whatReceive: 'Что получишь?',
  simplePrice: 'Обычная цена',
  strategyComplete: 'Готовая стратегия построения бренда личности, включая гид по контенту.',
  yourself: {
    ticker: 'Проявляй себя',
    MARKETER: {
      title: 'Ты знаешь, как взаимодействовать с людьми и какие каналы для этого использовать. Твоей работоспособности и стремлению достигать целей можно только позавидовать. \nУ нас есть для тебя несколько рекомендаций:\n',
      list: [
        'Систематизируй все вокруг, чтобы понимать, сколько усилий требует от тебя та или иная задача. Для этого тебе нужно разработать четкий план продвижения своего проекта;\n',
        'Не растворяйся в людях — расставляй границы и регулируй количество времени необходимое для общения;\n',
        'Будь избирателен — все социальные контакты важны, но определи для себя, какие из них — первой важности. Какие встречи будут более полезны для тебя прямо сейчас, а какие можно отложить на потом;\n',
        'Смести акцент с продвижения себя на продвижение своих проектов;\n',
        'Ты любишь соревноваться, но твой главный соперник — ты сам. Потому лучше сравнивай себя с собой вчерашним, чем с другими людьми;\n',
        'Чтобы быть уверенным в какой-нибудь гипотезе, ее нужно проверить на практике. Займись этим;\n',
        'Ты — очень самостоятельный и самодостаточный человек. Но поддержка и помощь других могут порой быть для тебя спасением. Не бойся ее принимать.'
      ]
    },
    INSIDER: {
      title: 'Ты активен, последователен, умеешь находить решения сложных проблем и тебе нет равных в переговорах и общении тет-а-тет. Ты — герой “сарафанного радио”\n. Продолжай развивать свои качества:\n',
      list: [
        'Общение один-на-один или в небольших группах — твоя суперсила. Делай на этом акцент и старайся как можно больше переговоров проводить в таком формате;\n',
        'Выделяй ежедневно фиксированное время для размышлений и генерации новых идей. Делай это регулярно и записывай все, что приходит в голову;\n',
        'Ты умеешь слышать других. Важно научиться также погружаться внутрь себя — замечать процессы, эмоции, мысли, переживания;\n',
        'Всегда расставляй приоритеты для каждой отдельной ситуации — это поможет держать фокус внимания;\n',
        'Обязательно попробуй протестировать свои организаторские способности: проведи мероприятие, собери группу людей и т.д.\n'
      ]
    },
    EXPERT: {
      title: 'Твой приоритет — развитие себя. Самое время начать делиться своими знаниями, опытом и взглядом на мир:\n',
      list: [
        'Сбалансируй в своем графике количество времени, проведенного наедине с собой со временем, проведенным в компании людей;\n',
        'Уделяй достаточное внимание планированию и структурированию своих целей и задач;\n',
        'Отойди от работы и попробуй что-то новое — проявляй креативность в новых для себя сферах, не бойся ошибаться и не останавливайся в попытках открыть для себя новые миры. Тебе важно реализовываться в сферах, в которых ты еще не являешься экспертом;\n',
        'Следи за количеством работы и задач — не начинай новые проекты, пока не финализировал старые;\n',
        'Заботься о себе: регулируй количество обязанностей и ответственности;\n',
        'Всегда помни о своем вкладе в работу. Подводя итоги проектов или анонсируя их, не забывай, что они стали возможными благодаря тебе и твоим способностям.\n'
      ]
    },
    CREATOR: {
      title: 'Притягательность, свобода и спонтанность — слова, которые характеризуют тебя лучше всего. Что делать дальше, чтобы строить целостный бренд личности?\n',
      list: [
        'Время подумать о своей карьере — куда ты планируешь расти и развиваться, какие у тебя стремления. Дай себе ответы на эти вопросы и зафиксируй цели;\n',
        'Следи за тем, с каким темпом ты достигаешь этих целей. Не стоит торопиться, важно осознавать — есть ли вообще движение в выбранном направлении;\n',
        'Ты — творец, а творцы любят быть везде и сразу. Попробуй по максимуму раскрыть свою креативность и энтузиазм в рамках конкретного проекта или одного места работы;\n',
        'Будь осторожен с критикой — не забывай о своей автономности и самобытности, цени себя как профессионала;\n',
        'Для тебя тема профессионального выгорания может быть очень актуальной, потому регулярно выделяй время на заботу о себе;\n',
        'Несмотря на твои грандиозные цели, твоя жизнь — это не только работа. Разнообразные активности помогут держать себя в тонусе. Пробуй новое.'
      ]
    },
    A_LISTER: {
      title: 'Быть в центре внимания для тебя — не ново. Но важно обратить внимание на несколько аспектов:\n',
      list: [
        'Публичные выступления созданы как будто специально для тебя — это важный шаг для развития твоего бренда личности. Можешь смело увеличивать их количество и раскрываться в этой сфере;\n',
        'Подумай о целях, которых хочешь достичь и проведи параллель между ними и твоими ценностями. Все ли совпадает?;\n',
        'Расширяй круг социальных контактов — больше общайся с людьми из своей сферы;\n',
        'Иногда ты сильно зависишь от чужого мнения. Научись ценить себя вне зависимости от оценки своих действий со стороны общества. Опирайся на свою экспертность, а не на мнения других;\n',
        'В тебе есть искра, а потому главное — не сгореть. Определи для себя оптимальное количество активностей за день. И не забывай про отдых — его можно тоже внести в календарь.'
      ]
    }
  },

  persons_message: [
    'Самый кайф — в первую \n' +
    'же неделю прилетели запросы именно на то, в чём хочу развиваться — визуальное сопровождение в ведении ЛБ \n' +
    'и бизнес аккаунтов. \n' +
    'Стоило только четко для себя сформулировать, что именно мне нужно. \n' +
    'Спасибо за все это. Вы 🔥🔥🔥\n',
    'Осознал, что сейчас действую \n' +
    'не по своей модели, и если это исправлю💪, добьюсь резуль- татов, к которым стремлюсь!🚀\n',
    'Где-то в подсознании я догады- вался что у меня есть способ- ности к созданию, но я не верил этому. Теперь попытаюсь хоть как-то что-то создавать, раз уж \n' +
    'я Сreator!💫\n' +
    '\n' +
    'Сам тест довольно необычный, на схожие вопросы отвечаешь по-разному, будто пытаешься мысленно смоделировать ситуацию🙌\n' +
    '\n',
    'Спасибо за интересный тест! Прям вопросы все в точку, боль каждого диджитал сотрудника)\n',
    'Моя модель Insider. Спершу \n' +
    'був трохи ступор і спротив 😅, \n' +
    'та коли отримала детальну стратегію, то зрозуміла \n' +
    'що таки моє. Тепер тільки втілювати в життя!🥰\n',
    'Дякую! Було дуже приємно 🖤🙈 бути серед тих, хто отримав такі знання. Я їх застосовую на собі і мій перший результат: просування на роботі. '
  ],
  author1: {
    name: 'Наталия \nПирак',
    description: 'Основательница маркетингового агентства Fly_Academy, на протяжении многих \n' +
      'лет работала с большими брендами, среди которых LVMH, JTI, Lactalis, Motorola, Nestle, Biersdorf, Goodyear, Loreal, Peugeot, Lifecell, Kimberly Clark и многие другие. В ее портфолио — коллаборации с Jay-Z и Дэвидом Линчем.'
  },
  author2: {
    name: 'София \nЛагутина',
    description: 'Врач-психолог, когнитивно-поведенческий терапевт, схема-терапевт, researcher Emotional Mental Imagery Lab \n' +
      'Uppsala University, Sweden\n' +
      'Technical University of Munich.\n'
  },
  passTest: {
    free: 'Пройди бесплатный тест на определение модели личности прямо сейчас. Он займет примерно 15 минут.\n',
    plain: 'Уже знаешь свою модель личности? У тебя есть возможность заказать развернутую стратегию построения твоего бренда личности.'
  },
  promo: {
    title: `Хочешь получить больше информации, которая поможет тебе построить сильный бренд личности?`,
    descriptions: ['Детальное описание твоей модели личности', 'Рекомендации по контенту', 'Схему шагов, необходимых для работы над своим брендом'],
    agree: 'Подтвердить',
    selectModel: 'Выбери модель, по которой хочешь получить детальный план',
    popupTitle: 'Внимание!',
    popupDescription: 'Было выбрано более 3-х стратегий. Мы понимаем, что иногда нелегко определиться;) Если тебе действительно нужно больше, отправь запрос на {{site}}',
    toPay: 'к оплате ',
    understand: 'Понятно',
    btn: "Оплатить",

  },
  checkStatus: 'Проверка статуса оплаты. Подождите, пожалуйста',
  error: {
    requiredField: "Пожалуйста, заполни это поле",
    requiredField_checkbox: "Нужно отметить чекбокс",
    globalError: "Произошла ошибка, повторите попытку позже",
    fillAtLeastOneField: "Нужно ввести хотя бы одну социальную сеть",
  },

  sidebar: {
    nav: {
      reviews: "Отзывы",
      about: "О тесте",
      models: "Модели бренда",
      benefits: "Что ты получишь",
      blog: "Блог",
    },
    startTest: "Пройти тест",
  },

  cookies: {
    text:
      "Продолжая посещение нашего сайта,\nты соглашаешься на использование файлов\n<0>cookie и с Политикой конфиденциальности</0>",
    btn: "Oк",
  },

  pageHome: {
    intro: {
      message:
        "Ты — бренд. \nУзнай, какие методы \nпроявления подходят \nименно тебе",
      description: "Тест на определение модели бренда личности",
      btn: "Подробнее",
    },

    ticker: {
      create: "НЕ копируй, а создавай",
      brand: "твой бренд личности",
      target: "Кому полезен тест",
    },

    about: {
      text: [
        "Все люди уникальны, с точки зрения личностных особенностей. Подход к построению бренда личности у каждого свой. Нет единого решения.",
        "Ты можешь ориентироваться на людей, чей бренд личности тебе нравится. Можешь анализировать успех, наблюдать за их действиями. Но в результате есть риск пойти ложным путем, создать фейковый образ и потерять себя.",
        "Важно оставаться верным себе. Мы создали 5 моделей бренда личности. Каждая модель основана на особенностях нервной системы и приобретенном опыте.",
        "Пройди тест, определи свою модель и получи стратегию построения бренда личности.",
      ],
      btn: "Подробнее",
    },

    participants: {
      title: "Кто уже прошел тест?",
    },

    chat: {
      title: "Мы на твоей стороне!",

      messages: [
        "Мне не свойственно делать то, что предлагают на курсах.\nКак быть?",
        "",
        "Агентства используют одинаковый подход для всех ",
        "Курсы по построению личного бренда  ничего не дали",
        "Какой контент создавать?",
        "Как начать работу над персональным брендом?",
      ],

      btn: "Узнать решение",
    },

    brand: {
      text: [
        "Ты наконец-то можешь создать собственный бренд, не переступая свои физиологические, психологические и социальные особенности. ",
        "Мы разработали пять моделей построения бренда личности. Для определения твоей модели мы создали биопсихосоциальный подход к тестированию.",
        "Тест определит особенности твоей нервной системы. Для этого важно узнать показатели Резилиенса — способности организма восстанавливаться после стресса, адаптироваться к меняющимся требованиям внешней среды. В результате — построить мощную стратегию создания бренда личности, подходящую именно тебе.",
        "Все разные: для кого-то характерна общительность и обмен энергией с социумом; кто-то ценит спокойствие и черпает вдохновение, обращаясь к своему внутреннему миру. Не ломай себя, чтобы построить бренд личности. Ты можешь сохранить аутентичность, не жертвуя успешностью, и достичь сверх результата. ",
      ],
    },

    model: {
      title: "Модели бренда личности",
      creator: {
        name: "Creator",
        description:
          "Контент — король и Криэйторы знают, как рассказать захватывающую историю о событиях, личностях, хобби и лайфстайле.\n\nБлог, стрим, YouTube-канал, TikTok, или же компания друзей — аудитории интересно следить за каждым шагом.",
      },
      expert: {
        name: "Expert",
        description:
          "Знания и опыт для Экспертов важнее публичности, несмотря на то, что именно они привлекают  внимание к своей индустрии.  \n\nРазвитие и возможность делиться знаниями — главные приоритеты Экспертов.",
      },
      insider: {
        name: "Insider",
        description:
          "Инсайдер понимает важность  контактов. Знает, что главная ценность идеи, компании, бизнеса — это люди. \n\nЛичная рекомендация для них важнее присутствия в соцсетях. Инсайдеры активны и последовательны, легко находят неочевидные решения. ",
      },
      marketer: {
        name: "Marketer",
        description:
          "Маркетеры продвигают себя с помощью своих дел. Они не обращают внимания на навязанные нормы. \n\nМаркетеры умеют взаимодействовать с людьми и готовы к проявлениям в разных каналах коммуникации.  ",
      },
      lister: {
        name: "A-lister",
        description:
          "А-листеров узнают, что бы они ни делали: блог, карьеру на телевидении или собственный бизнес. Их имена становятся нарицательными и не нуждаются в описании их занятий.\n\nЧто характерно для А-листеров? Активное взаимодействие в обществе и большое количество социальных контактов. \n",
      },
      you: {
        name: "А кто ты?",
        btn: "Узнать модель",
      },
    },

    propose: {
      title: "Узнай свою модель \nбренда личности ",
      description: "Тест займет примерно 15 минут",
      btn: "Начать тест",
      free: "Бесплатно",
    },

    benefits: {
      title: "Ты получишь:",

      list: [
        "Описание твоей модели бренда личности.\nМы исходим из особенностей твоей нервной системы\nи биопсихосоциальных качеств.",
        "Готовую стратегию построения бренда личности, включая гид по контенту. Тебе больше не придется платить деньги за курсы и консультации, а главное — тратить время.",
        "Ответ, по какой модели ты действуешь и что нужно изменить и усилить, для достижения поставленных целей в кратчайшие сроки.",
        "Структурированный пошаговый план, который исключит трату лишних ресурсов для достижения результата. ",
        "Инструмент для составления личного уникального сообщения, с которым ты выйдешь в публичное пространство.  ",
      ],
    },

    faq: {
      btn: "Пройти тест",

      questions: [
        {
          question: "Ты ищешь системный научный подход?",
          answer:
            "Ты уже работаешь над персональным брендом и понимаешь его ценность. Но общая информация не учитывает особенности твоей личности. Тебе нужны гибкие инструменты, которые подойдут именно твоей нервной системе. Ты не хочешь тратить время и усилия на проверку  общих гипотез и рекомендаций, пересматривать часы видеороликов на YouTube.\n\nМы дадим глубинное понимание твоих личностных паттернов поведения и расскажем, как развивать то, что понравится и подойдет именно тебе. Результаты и рост не заставят себя ждать.",
        },
        {
          question: "Нужно начать. Но с чего?",
          answer:
            "Ты понимаешь что бренд личности необходим для карьеры, экспертизы, репутации в сфере и роста прибыли. Но каждый раз чувствуешь растерянность: “с чего начать?”. Пугает количество решений, которые нужно принять. Нет уверенности в результате. Это демотивирует и отодвигает создание бренда личности в списке твоих ежедневных задач. Боишься неудачи, ведь хочешь строить бренд на фундаменте своей, а не чужой личности. Нормально изучать истории успеха других, но их нельзя просто взять и скопировать. Так и с моделью бренда особистості. Тест на определение модели даст четкий и структурированный план, с помощью которого ты будешь действовать уверенно и в своем ритме.",
        },
        {
          question: "Зачем тебе бренд личности?",
          answer:
            "Ты видишь людей с мощным персональным брендом и думаешь — с чего начать? Что в твоей личности станет стартовой точкой для запуска машины из роста аудитории, новых возможностей и развития твоего дела? Знаешь ли ты о себе достаточно, чтобы начать распространять значимые для тебя идеи, рассказывать о своих достижениях, вдохновлять других и привлекать новые возможности? Работа над собой — единственное выгодное вложение ресурсов. Определи свою модель бренда личности и узнай удобный способ взаимодействия с аудиторией и проявления себя. ",
        },
      ],
    },
    footer: {
      policy: "Политика\nконфиденциальности",
      help: "Связаться\nс нами",
    },
  },

  pageError: {
    "404": "Страница не найдена",
    "500": "Произошла ошибка. Повторите попытку позже",
    "isTechnical": `Извините идут технические работы. Мы становимся лучше для вас❤️`,
    homeBtn: "На главную",
  },

  pageBlogList: {
    title: "Наш блог",

    card: {
      read: "Читать",
    },

    ads: {
      title:
        `Ты — бренд.
Узнай, какие методы <br/>проявления
подходят <br/>именно тебе`,
      text: "Тест на определение модели бренда личности",
      btn: "Начать тест",
    },
  },

  pageTest: {
    actions: {
      back: "Назад",
      next: "Далее",
      yes: "Да",
      no: "Нет",
    },

    field: {
      placeholder: "Выбери вариант из списка",
    },

    intro: {
      title: "Тест на определение модели \nбренда личности",
      text:
        "Тебя ждут вопросы о простых жизненных ситуациях, представь себя\nв каждой из них и дай ответ. Это займет не более 15 минут.\n\n" +
        "Пройдя тест, ты узнаешь, по какой модели сейчас живешь и действуешь. Основная же ценность  –  определение твоей модели Бренда Личности, основанной на уникальных качествах твоего характера, особенностях нервной системы и приобретенном в социуме опыте.  \n\nДействуй!",
      btn: "Регистрация",
      ticker: "Действуй",
    },

    registration: {
      title_registration: "Давай познакомимся!",
      "title_final-registration": "Поздравляем тебя!",

      description_registration:
        "Заполни поля для регистрации\nи ты получишь результат на почту",
      "description_final-registration":
        "Тест пройден. Сейчас ты узнаешь свою модель бренда личности.\nМы отправим результат тебе на Email. Проверяй!",
      name: "Введи свое имя",
      email: "Введи свой E-mail",
      lastName: "Введи свою фамилию",
      age: "Введи свой возраст",
      onlyNumber: 'поле принимает только цифры',
      fb: "Войти через FB",
      google: "Войти через Google",
      submit_registration: "Начать тест",
      "submit_final-registration": "Подтверждаю",

      agreeTerms: "Я соглашаюсь на обработку персональных данных ",
      agreeMailing:
        "Хочу получать информацию для развития своего Бренда Личности",
    },

    socialQuestion: {
      title: "В каких социальных сетях тебя можно найти?",
      description:
        "Мы ежемесячно  дарим аудит профилей и это может быть твой.\n" +
        "Заполни поля соцсетей.\n\n" +
        "Осталось сделать несколько шагов к твоей модели бренда личности!",
      submit: "Узнать результат",
      bound: "Подключено",
    },

    result: {
      text: "Поздравляем!\nРезультат отправлен на твою почту ",
      Failure: "Ой, что-то пошло не так.\nПроверь данные по оплате или свяжись с банком.\n ",
      btn: "На главную",
    },
  },

  pageResult: {
    ticker: {
      intro: "Ты {{ role }}!",
      intro2: "Ты {{ role }}.",
      content: "ТВОЙ КОНТЕНТ",
      plan: "План действий",
      slogan: "Истинное влияние ведет к действию",
    },

    intro: {
      share: {
        title: "Расскажи друзьям! ",
        btn: "Скачать видео",
      },
    },
    properties: {
      title: "Пойми себя",
    },

    congratulation: {

      title: "Теперь ты знаешь свою модель личности",

      description:
        "Биопсихосоциальная модель построена на: уникальных качествах твоего характера, особенностях нервной системы и приобретенном в социуме опыте. У тебя уже заложены все перечисленные качества, осталось научиться их использовать.\n\nТвоя модель бренда личности самая подходящая для твоей нервной системы. Смело переходи к рекомендациям по контенту — уже без стресса. Контент — фундамент стратегии твоего бренда.",
    },

    model: {
      title: "Сейчас ты действуешь по модели {{ role }}",
    },

    plan: {
      description:
        "Мы создали для тебя план из 5 шагов по методологии <0>Fly_Academy</0>. Ты сможешь достичь поставленной цели и ускориться на пути к построению бренда личности.\nТеперь все в твоих руках:	",

      personal: {
        title: "Уникальное личное предложение",
        description:
          "Честно ответь на вопросы ниже и сформируй уникальное личное предложение, чтобы двигаться в своем ритме. Это основа твоего бренда личности, то, как люди будут воспринимать тебя.",

        question: [
          "Что я люблю делать? ",
          "Что я делаю лучше всего? ",
          "Чего ждет моя аудитория?",
        ],
      },
    },
    propose: {
      title: "Закажи стратегию бренда личности и получи:",
      titleNew: 'Стратегия бренда личности',
      description: 'Узнай как {{model}} может посторить влиятельный бренд личности без стресса',

      btn: "Оплатить",
      list: [
        `Стратегия построения бренда личности, и гид по контенту. Больше не придется платить за курсы и тратить время.`,
        "Ответ, по какой модели ты действуешь и что нужно изменить и усилить, для достижения поставленных целей в кратчайшие сроки.",
        "Структурированный пошаговый план, который исключит трату лишних ресурсов для достижения результата. ",
        "Инструмент для составления личного уникального сообщения, с которым ты выйдешь в публичное пространство.  ",
      ],
      listPhone: [
        `Готовую стратегию построения бренда личности, включая гид по контенту. Тебе больше не придется платить деньги за курсы и консультации, а главное — тратить время.`,
        "Ответ, по какой модели ты действуешь и что нужно изменить и усилить, для достижения поставленных целей в кратчайшие сроки.",
        "Структурированный пошаговый план, который исключит трату лишних ресурсов для достижения результата. ",
        "Инструмент для составления личного уникального сообщения, с которым ты выйдешь в публичное пространство.  ",
      ],
    },

    final: {
      title:
        "Первый шаг сделан. Теперь ты построишь бренд личности по cвоим правилам. \n" +
        "В этом тебе поможет «Книга бренда личности» – методичка, с которой, ты самостоятельно легко сформируешь свой бренд. \n",
      description:
      "Остались вопросы? Обратись за личной консультацией в агентство по построению бренда личности",
      share: {
        hashtag: "#моямодельличности",
      },
    },
  },
};

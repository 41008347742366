import { useEffect, useState } from "react";

function useField<T>(initialValue: T, withResetErrorOnChange = true) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");

  useEffect(() => {
    withResetErrorOnChange && setError("");
  }, [value, withResetErrorOnChange]);

  return {
    value,
    error,
    change: setValue,
    changeError: setError,
  };
}

export default useField;

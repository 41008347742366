import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
const cx = classNames.bind(styles);

export const BenefitsList = ({
  className = "",
  list,
}: {
  list: string[];
  className?: string;
}) => {
  return (
    <div className={className}>
      {list.map((text, index) => {
        return (
          <div className={cx("Item")}>
            <div className={cx("ItemNumber")}>{1 + index}</div>
            <p className={cx("ItemText")}>{text}</p>
          </div>
        );
      })}
    </div>
  );
};

const Benefits = () => {
  const [t] = useTranslation();
  const benefits: string[] = t("pageHome.benefits.list", {
    returnObjects: true,
  });
  const benefitsPhone: string[] = t("pageHome.benefits.listPhone", {
    returnObjects: true,
  });

  return (
    <section id="benefits" className={cx("Component")}>
      <div className={cx("container")}>
        <h2 className={cx("Title")}>{t("pageHome.benefits.title")}</h2>

        <div className={cx("Content")}>
          <div className={cx("ImageBlock")}>
            <img className={cx("phone")} src={require("assets/images/phone-01.svg")} alt="" />
            <img className={cx("face")} src={require("assets/images/face.jpg")} alt="" />
          </div>
          <BenefitsList list={benefits} className={cx("List")} />
          <BenefitsList list={benefitsPhone} className={cx("ListPhone")} />
        </div>
      </div>
    </section>
  );
};

export default Benefits;

import React, {FC, useEffect, useRef, useState} from 'react'
import classNames from "classnames/bind";
import s from './index.module.scss'

const cx = classNames.bind(s)

const bg = {
  1: "#c0e30e",
  2: "#fc8ab8",
  3: "#bb86ff",
  4: "#c0e30e",
  5: "#bb86ff",

} as any
export const PlayVideo: FC<{ className?: string }> = ({className}) => {

  const [index, setIndex] = useState<number>(1)
  const [colorIndex, setColorIndex] = useState<number>(1)
  const [hide, setHide] = useState<boolean>(false)

  const videoRef = useRef<any>()

  const path = process.env.NODE_ENV === "development" ? process.env.PUBLIC_URL + `/assets/videos/${index}.mp4` : `/assets/videos/${index}.mp4`;
  const handleEnded = () => {
    setColorIndex(prevState => (prevState >= 5) ? 1 : +prevState + 1)
    setHide(true)
    setTimeout(() => {
      setHide(false)
      setIndex(prevState => {
        return (prevState >= 5) ? 1 : +prevState + 1
      })
    }, 1000)
  }
  useEffect(() => {
    const ref = videoRef?.current as HTMLVideoElement
    ref?.load()
  }, [index])

  const handlePlay = (event: any) => {
    const current = videoRef.current as HTMLVideoElement
    current.play()
  }
  const styles = {
    backgroundColor: bg[colorIndex]
  }
  return <>
    <div className={cx("posterVideoColor")}
         style={styles}
    />
    <video className={cx("Video", {hide})}
           muted
           ref={videoRef}
           onEnded={handleEnded}
           onCanPlayThrough={handlePlay}
           preload={'auto'}
           playsInline
           controls={false}
           autoPlay={true}
           src={process.env.NODE_ENV === "development" ? `https://personalbrandmodel.com/assets/videos/${index}.mp4` : path}
    />
  </>
}

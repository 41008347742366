import React, {FC} from "react";
import Button from "../Button";
import {ROUTES} from "../../App";
import s from './index.module.scss'
import classNames from "classnames/bind";
import {useHistory} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {OrderStatus} from "../../api/generated";

const cx = classNames.bind(s)

type StatusResultType = {
  result?: OrderStatus,
  pagePayment?: boolean
}
export const StatusResult: FC<StatusResultType> = ({
                                                     result = OrderStatus.Success,
                                                     pagePayment = false
                                                   }): JSX.Element => {
  const history = useHistory()
  const [t, {language: lang}] = useTranslation();
  const isFailure = (result === OrderStatus.Failure) ||( result === OrderStatus.Decline)
  return <div className={cx("Result")}>
    {(!pagePayment && !isFailure) && <img src={require("assets/images/rocket.png")} alt=""/>}
    <p className={cx("ResultText")}>{t(`pageTest.result.${isFailure ? "Failure" : "text"}`)}</p>
    <Button onClick={() => history.push(ROUTES.home)}>
      {t("pageTest.result.btn")}
    </Button>
  </div>
}
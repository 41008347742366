import React, {FC, ReactNode} from 'react'
import classNames from 'classnames/bind'
import s from './index.module.scss';

const cx = classNames.bind(s)

const DoubleFrame: FC<{ children: ReactNode | string }> = ({children}) => {
  return <div className={cx('Frame')}>
    {children}
  </div>
}
export default DoubleFrame

import Button, {ButtonProps} from "../Button";
import {StarSvg} from "../../assets/icons/svg/star";
import React, {memo, FC} from "react";
import s from './index.module.scss';
import classNames from 'classnames/bind';
import {ButtonWithStarPropsType} from "./index.props";
import {useTranslation} from "react-i18next";
import {PRICE} from "constants/constants";

const cx = classNames.bind(s)
const ButtonWithStar: FC<ButtonWithStarPropsType & ButtonProps> = (props) => {
  const [t] = useTranslation()
  return (
    <div className={`${cx('WrapButtons')}${props.classNamesWrap ? ' ' + props.classNamesWrap : ''}`}>
      <div className={cx('ContainerBtn')}>
        <Button onClick={props.onClick}
                className={`${cx('Submit', {SubmitWhite: props.white})}${props.classNamesButton ? ' ' + props.classNamesButton : ''}`}
                withArrow={props.withArrow}
                {...props}
        >
          <>
            {t(props.text ? props.text : "personalityBrandStrategy")}
            <span className={'strike'}>{props.oldPrice}</span>{props.currency || PRICE.currency}
          </>
        </Button>
      </div>
      <div className={cx('Star')}>
        <StarSvg text={props.newPrice} fill={props.white ? '#fff' : '#C3F20C'}/>
      </div>
    </div>
  )
}
export default memo(ButtonWithStar)

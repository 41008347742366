export default {
  ru: 'Рос',
  uk: 'Укр',
  en: 'Анг',
  'More': 'Дізнатися',

  'Your workbook': 'Твоя книга бренду',
  moreInfo: 'Більше',
  orderAStrategy: 'Замовити стратегію',
  personalityBrandStrategy: `Стратегія бренду особистості {{price}}`,
  whatReceive: 'Що отримаєш?',
  simplePrice: 'Звичайна ціна',
  strategyComplete: 'Готова стратегія побудови персонального бренду, включаючи гід по контенту.',
  yourself: {
    ticker: 'Проявляй себе',
    MARKETER: {
      title: 'Ти знаєш, як взаємодіяти з людьми і які канали для цього використовувати. Твоїй працездатності і прагненню досягати цілей можна тільки позаздрити. У нас є для тебе кілька рекомендацій:\n',
      list: [
        'Систематизуй усе навколо, щоб розуміти, скільки зусиль вимагає від тебе та чи інша задача. Для цього тобі потрібно розробити чіткий план просування свого проекту;\n',
        'Не розчиняйся в людях - розставляй кордони і регулюй кількість часу необхідного для спілкування;\n',
        'Будь вибірковим - усі соціальні контакти важливі, але визнач для себе, які з них - першої необхідності. Які зустрічі будуть більш корисні для тебе прямо зараз, а які можна відкласти на потім;\n',
        'Посунь акцент з просування себе на просування своїх проектів;\n',
        'Ти любиш змагатися, але твій головний суперник — ти сам. Тому краще порівнюй себе з собою вчорашнім, ніж з іншими людьми;\n',
        'Щоб бути впевненим в якій-небудь гіпотезі, її потрібно перевірити на практиці. Зроби це;',
        'Ти - дуже самостійна і самодостатня людина. Але підтримка і допомога інших можуть часом бути для тебе порятунком. Не бійся її приймати.'
      ]
    },
    INSIDER: {
      title: 'Ти активний, послідовний, вмієш знаходити рішення складних проблем і тобі немає рівних у перемовинах і спілкуванні тет-а-тет. Ти — герой "сарафанного радіо". Продовжуй розвивати свої якості:\n',
      list: [
        'Спілкування один-на-один або в невеликих групах - твоя суперсила. Роби на цьому акцент і намагайся якомога більше переговорів проводити в такому форматі;\n',
        'Виділяй щодня фіксований час для роздумів і генерації нових ідей. Роби це регулярно і записуй все, що приходить в голову;\n',
        'Ти вмієш чути інших. Важливо навчитися також занурюватися всередину себе - помічати процеси, емоції, думки, переживання;\n',
        'Завжди розставляй пріоритети для кожної окремої ситуації - це допоможе тримати фокус уваги;\n',
        'Обов\'язково протестуй свої організаторські здібності: проведи захід, збери групу людей. \n'
      ]
    },
    EXPERT: {
      title: 'Твій пріоритет — саморозвиток. Зараз саме час почати ділитися своїми знаннями, досвідом і поглядом на світ:\n',
      list: [
        'Збалансуй у своєму графіку кількість часу, проведеного наодинці з собою з часом, проведеним у компанії людей;\n',
        'Приділяй достатню увагу плануванню і структуруванню своїх цілей і завдань;\n',
        'Відійди від роботи і спробуй щось нове — проявляй креативність у нових для себе сферах, не бійся помилятися і не зупиняйся у спробах відкрити для себе нові світи. Тобі важливо реалізовуватися у сферах, в яких ти ще не є експертом;\n',
        'Стеж за кількістю роботи і завдань — не розпочинай нові проекти, поки не фіналізував старі;\n',
        'Піклуйся про себе: регулюй кількість обов\'язків і відповідальності;',
        'Завжди пам\'ятай про свій внесок у роботу. Підводячи підсумки проектів або анонсуючи їх, не забувай, що вони стали можливими завдяки тобі і твоїм здібностям.\n',
      ]
    },
    CREATOR: {
      title: 'Привабливість, свобода і спонтанність — слова, які характеризують тебе якнайкраще. Що робити далі, щоб будувати цілісний бренд особистості?\n',
      list: [
        'Час подумати про свою кар\'єру - куди ти плануєш рости і розвиватися, які у тебе прагнення. Дай собі відповіді на ці питання і зафіксуй цілі;\n',
        'Стеж за тим, з яким темпом ти досягаєш цих цілей. Не варто поспішати, але важливо усвідомлювати, чи є взагалі рух в обраному напрямку;\n',
        'Ти - творець, а творці полюбляють бути скрізь і одразу. Спробуй максимально розкрити свою креативність і ентузіазм в рамках конкретного проекту або одного місця роботи;\n',
        'Будь обережний з критикою - не забувай про свою автономність і самобутність, цінуй себе як професіонала;\n',
        'Для тебе тема професійного вигорання може бути дуже актуальною, тому регулярно виділяй час на турботу про себе;\n',
        'Незважаючи на твої грандіозні цілі, життя - це не тільки робота. Різноманітні активності допоможуть тримати себе в тонусі. Спробуй нове.\n'
      ]
    },
    A_LISTER: {
      title: 'Бути в центрі уваги для тебе — не щось нове. Але важливо звернути увагу на кілька аспектів:\n',
      list: [
        'Публічні виступи ніби створені спеціально для тебе — це важливий крок для розвитку твого бренду особистості. Можеш сміливо збільшувати їх кількість і розкриватися в цій сфері;\n',
        'Подумай про цілі, яких хочеш досягти і проведи паралель між ними та твоїми цінностями. Чи все збігається?;\n',
        'Розширюй коло соціальних контактів — більше спілкуйся з людьми зі своєї сфери;\n',
        'Часом ти сильно залежиш від чужої думки. Навчися цінувати себе незалежно від оцінки своїх дій з боку оточуючих. Спирайся на свою експертність, а не на думки інших;\n',
        'У тобі є іскра, а тому головне — не згоріти. Визнач для себе оптимальну кількість активностей на день. І не забувай про відпочинок — його можна теж занести в календар.'
      ]
    }
  },

  persons_message: [
    'Найбільший кайф - в першу \n' +
    'Ж тиждень прилетіли запити саме на те, в чому хочу розвиватися - візуальний супровід у веденні ЛБ \n' +
    'І бізнес акаунтів. \n ' +
    'Варто було тільки чітко для себе сформулювати, що саме мені потрібно. \n ' +
    'Спасибі за все це. Ви 🔥🔥🔥 \n ',
    'Усвідомив, що зараз діють \n' +
    'Не по своїй моделі, і якщо це ісправлю💪, доб\'юся резуль- татів, до яких прагну! 🚀 \n',
    'Десь в підсвідомості я догадувався що у мене є спосібності до створення, але я не вірив цьому. Тепер спробую хоч якось щось створювати, якщо вже \n ' +
    'Я Сreator! 💫 \n' +
    '\n' +
    'Сам тест досить незвичайний, на схожі питання відповідаєш по-різному, ніби намагаєшся подумки змоделювати сітуацію🙌 \n' +
    '\n',
    'Спасибі за цікавий тест! Прям питання все в точку, біль кожного Діджитал співробітника) \n ',
    'Моя модель Insider. Спершись \n ' +
    'БУВ немного ступор и спротив 😅, \n' +
    'Та коли получила детальну стратегію, то зрозуміла \n' +
    'Что таки моє. Тепер только втілюваті в життя! 🥰 \n ',
    'Дякую! Було дуже приємно 🖤🙈 бути серед тих, хто отримав такі знання. Я їх застосовую на собі і мій перший результат: просування на роботі. '
  ],
  authorTitle: 'Автори тесту на визначення моделі особистості:',
  author1: {
    name: 'Наталія \nПірак',
    description: 'Засновниця маркетингового агентства Fly_Academy, протягом багатьох років працювала з найвідомішими брендами, серед яких LVMH, JTI, Lactalis, Motorola, Nestle, Biersdorf, Goodyear, Loreal, Peugeot, Lifecell, Kimberly Clark і багато інших. В її портфоліо – колаборації з Jay-Z і Девідом Лінчем. ',
  },
  author2: {
    name: 'Софія \nЛагутіна',
    description: 'Лікар-психолог, когнітивно-поведінковий терапевт, схема-терапевт, researcher Emotional Mental Imagery Lab \n' +
      'Uppsala University, Sweden\n' +
      'Technical University of Munich.\n'
  },
  passTest: {
    free: 'Пройди безкоштовний тест на визначення моделі особистості прямо зараз. Він займе приблизно 15 хвилин.',
    plain: 'Вже знаєш свою модель особистості? У тебе є можливість замовити розгорнуту стратегію побудови твого бренду особистості.'
  },
  promo: {
    title: `Хочеш отримати більше інформації, яка допоможе тобі побудувати сильний персональний бренд?`,
    descriptions: ['Детальний опис твоєї моделі особистості', 'Рекомендації щодо контенту', 'Схему кроків, необхідних для роботи над своїм брендом'],
    agree: 'Підтвердити',
    selectModel: 'Обери модель, за якою хочеш отримати детальний план:',
    popupTitle: 'Увага!',
    popupDescription: ' Було обрано більше 3-х стратегій. Ми розуміємо, що іноді нелегко визначитися;) Якщо тобі справді потрібно більше, надішли запит на {{site}}',
    toPay: 'До сплати ',
    understand: 'Зрозуміло',
    btn: "Cплатити",

  },
  checkStatus: ' Перевірка статусу оплати. Зачекайте, будь ласка',

  error: {
    requiredField: "Будь ласка, заповни це поле",
    requiredField_checkbox: "Потрібно відмітити чекбокс",
    globalError: "Сталася помилка, спробуйте ще раз пізніше",
    fillAtLeastOneField: "Потрібно ввести хоча б одну соціальну мережу",
  },

  sidebar: {
    nav: {
      reviews: "Відгуки",
      about: "Про тест",
      models: "Моделі бренду",
      benefits: "Що ти отримаєш",
      blog: "Блог",
    },
    startTest: "Пройти тест",
  },

  cookies: {
    text:
      "Продовжуючи відвідування нашого сайту, \nти погоджуєшся на використання файлів \n <0>cookie і з Політикою конфіденційності</0>",
    btn: "Oк",
  },

  pageHome: {
    intro: {
      message:
        "Ти — бренд! \nДізнайся, якими \nзасобами тобі властиво \nпроявляти себе",
      description: "Тест на визначення моделі бренду особистості",
      btn: "Детальнiше",
    },

    ticker: {
      create: "Не копіюй, а створюй!",
      brand: "Твій бренд особистості за твоїми правилами",
      target: "Для кого корисний тест",
    },

    about: {
      text: [
        "Усі люди унікальні, з боку особистісних характеристик.",
        "Кожен має власний підхід до створення бренду особистості. Єдиного рішення немає.",
        "Ти можеш орієнтуватися на людину, чий бренд особистості тобі подобається. Можеш аналізувати успіх, спостерігати за діями. Але, зрештою, є ризик обрати невірний шлях, створити фейковий образ і втратити себе.",
        "Важливо залишатися вірними собі. Ми створили 5 моделей особистості. Кожна модель базується на особливостях нервової системи й набутому досвіді.",
        "Проходь тест, визначай власну модель і отримуй стратегію побудови бренду особистості.",
      ],
      btn: "Детальнiше",
    },

    participants: {
      title: "Хто вже пройшов тест?",
    },

    chat: {
      title: "Ми на твоєму боці",

      messages: [
        "Мені не властиво робити те, що пропонують на курсах.\nЯк бути?",
        "",
        "Агентства використовують однаковий підхід для всіх",
        "Курси з побудови особистого бренду нічого не дали",
        "Який контент створювати?",
        "Як розпочати роботу над персональним брендом?",
      ],

      btn: "Дізнатися рішення",
    },

    brand: {
      text: [
        "Ти, нарешті, можеш створити бренд особистості, не нехтуючи власними фізіологічними, психологічними й соціальними особливостями.",
        "Ми розробили п‘ять моделей побудови бренду особистості. Для визначення твоєї моделі ми створили біопсихосоціальний підхід до тестування.",
        "Тест визначить особливості твоєї нервової системи. Для цього важливо дізнатися показники Резілієнсу — здатності організму відновлюватись після стресу, адаптуватися до мінливих факторів навколишнього середовища. В результаті — побудувати потужну стратегію створення бренду особистості, яка підходить саме тобі.",
        "Всі різні: комусь властиво багато спілкуватися й обмінюватися енергією з соціумом; хтось цінує спокій та надихається наодинці з власним внутрішнім світом. Не ламай себе, щоб збудувати бренд особистості. Ти можеш зберегти автентичність, не приносячи в жертву успішність, та досягнути надзвичайного  результату.",
      ],
    },

    model: {
      title: "Моделі бренду особистості",
      creator: {
        name: "Creator",
        description:
          "Контент - король і Кріейтори знають, як розповісти захоплюючу історію про події, постатей, хобі та лайфстайл. \n\nБлог, стрім, YouTube-канал, TikTok, або ж компанія друзів - аудиторії цікаво стежити за кожним кроком.",
      },
      expert: {
        name: "Expert",
        description:
          "Знання та досвід для Експертів важливіші за публічність, не дивлячись на те, що саме вони привертають увагу до своєї індустрії. \n\nРозвиток і можливість ділитися знаннями - головні пріоритети Експертів.",
      },
      insider: {
        name: "Insider",
        description:
          "Інсайдер розуміє важливість контактів. Знає, що головна цінність ідеї, компанії, бізнесу - це люди. \n\nОсобиста рекомендація для них важливіша за присутність в соцмережах. Інсайдери активні й послідовні, легко знаходять неочевидні рішення.",
      },
      marketer: {
        name: "Marketer",
        description:
          "Маркетери просувають себе за допомогою своїх справ. Вони не звертають уваги на нав'язані норми. \n\nМаркетери вміють взаємодіяти з людьми і готові до проявів в різних каналах комунікації.",
      },
      lister: {
        name: "A-lister",
        description:
          "А-Лістерів впізнать, що б вони не робили: блог, кар'єру на телебаченні або власний бізнес. Їхні імена стають прозивними й не потребують опису їхніх занять. \n\nЩо характерно для А-Лістерів? Активна взаємодія в суспільстві й велика кількість соціальних контактів.",
      },
      you: {
        name: "А хто ти?",
        btn: "Дізнайся модель",
      },
    },

    propose: {
      title: "Дізнайся власну модель\nбренду особистості",

      description: "Ти витратиш приблизно 15 хвилин",
      btn: "Почати тест",
      free: "Безкоштовно",
    },

    benefits: {
      title: "Ти отримаєш:",

      list: [
        "Опис твоєї моделі бренду особистості. Ми виходимо з особливостей твоєї нервової системи й біопсихосоціальних якостей.",
        "Готову стратегію побудови бренду особистості, включно з гідом з контенту. Ти більше не муситимеш платити гроші за курси, а головне — витрачати час.",
        "Відповідь, згідно з якою моделлю ти дієш, та що треба змінити або посилити, для досягнення цілей в стислі терміни.",
        "Структурований покроковий план, який виключатиме витрачання зайвих ресурсів для досягнення результату.",
        "Інструменти для того, щоб скласти особисту унікальну пропозицію, з якою ти вийдеш в публічний простір",
      ],
    },

    faq: {
      btn: "Проходь тест",

      questions: [
        {
          question: "Ти шукаєш системний науковий підхід?",
          answer:
            "Ти вже працюєш над персональним брендом і розумієш його цінність. Але загальна інформація не враховує характеристики твоєї особистості. Тобі потрібні гнучкі інструменти, які пасуватимуть саме твоїй нервовій системі. Ти не хочеш витрачати час та зусилля на перевірку загальних гіпотез та рекомендацій, передивлятися години відеороліків на YouTube. Ми дамо глибинне розуміння твоїх особистісних патернів поведінки й розповімо, як розвивати те, що сподобається та пасуватиме саме тобі. Та вже скоро ти побачиш результати й власний розвиток.",
        },
        {
          question: "Потрібно почати. Але з чого?",
          answer:
            "Ти розумієш, що особистий бренд потрібен для кар‘єри, експертизи, репутації в галузі й зростання прибутку. Але щоразу губишся: “з чого розпочати?” Тебе лякає кількість рішень, які треба ухвалити. Немає впевненості в результаті. Це демотивує й відкладає роботу над персональним брендом в переліку твоїх щоденних задач. Боїшся невдач, адже хочеш будувати бренд на фундаменті власної, не чиєїсь особистості. Нормально вивчати історії успіху інших, але їх не можна просто взяти й скопіювати. Так само з моделлю бренду особистості. Тест на визначення моделі дасть тобі чіткий та структурований план, за допомогою якого ти діятимеш впевнено та у власному ритмі.",
        },
        {
          question: "Навіщо тобі бренд особистості?",
          answer:
            "Ти бачиш людей з потужним персональним брендом і думаєш — з чого розпочати? Що у твоїй особистості буде стартом для запуску машини зі зростання аудиторії, нових можливостей і розвитку твоєї справи? Чи знаєш ти про себе достатньо, щоб розпочати поширювати важливі для тебе ідеї, розповідати про досягнення, надихати інших та приваблювати нові можливості? Робота над собою — єдине, куди варто вкладати ресурси. Визнач власну модель бренду особистості, дізнайся, як зручно взаємодіяти з аудиторією та проявляти себе.",
        },
      ],
    },
    footer: {
      policy: "Політика\nконфіденційності",
      help: "Зв‘язатися\nз нами",
    },
  },

  pageError: {
    "404": "Сторінку не знайдено",
    "500": "Сталася помилка. Повторіть спробу пізніше",
    "isTechnical": `Вибачте йдуть технічні роботи.  Ми стаємо кращими для вас❤️`,

    homeBtn: "На головну",
  },

  pageBlogList: {
    title: "Наш блог",

    card: {
      read: "Читати",
    },

    ads: {
      title: `Ти - бренд. 
Дізнайся, які методи <br/>прояви 
підходять <br/>саме тобі`,
      text: "Тест на визначення моделі бренду особистості",
      btn: "Почати тест",
    },
  },

  pageTest: {
    actions: {
      back: "Назад",
      next: "Далі",
      yes: "Так",
      no: "Ні",
    },

    field: {
      placeholder: "Обери варіант зі списку",
    },

    intro: {
      title: "Тест на визначення моделі\nбренду особистості",
      text:
        "На тебе чекають запитання про прості життєві ситуації, уяви себе в кожній з них та надай відповідь. Це відніме не більш як 15 хвилин. \n\n" +
        "Після проходження тесту ти дізнаєшся, за якою моделлю живеш та дієш зараз. Але основна цінність — визначення твоєї моделі бренду особистості, яка базується на унікальних якостях твого характеру, особливостях нервової системи та набутому в соціумі досвіді. \n\nДій! ",
      btn: "Реєстрація",
      ticker: "Дій",
    },
    registration: {
      title_registration: "Давай познайомимося!",
      "title_final-registration": "Вітаємо тебе!",

      description_registration:
        "Заповни поля для реєстрації \ni ти отримаєш результат на пошту",
      "description_final-registration":
        "Тест пройдено. Зараз ти дізнаєшся свою модель бренду особистості. \nМи відправимо результат тобі на Email. Перевіряй!",
      name: "Введи своє ім'я",
      email: "Введи свій E-mail",
      lastName: "Введи своє прізвище",
      age: "Введи свій вік",
      onlyNumber: 'поле приймає лише цифри',

      fb: "Увійти через FB",
      google: "Увійти через Google",
      submit_registration: "Почати тест",
      "submit_final-registration": "Підтверджую",

      agreeTerms: "Я погоджуюся на обробку персональних даних",
      agreeMailing:
        "Хочу отримувати інформацію для розвитку свого Персонального Бренду",
    },

    socialQuestion: {
      title: "В яких соціальних мережах тебе можна знайти?",
      description:
        "Ми щомісяця даруємо аудит профілів. Одного разу ми можемо обрати твій.\n" +
        "Заповни поля соцмереж нижче. \n\n" +
        "Залишилось декілька кроків до твоєї моделі бренду особистості!",
      submit: "Результат",
      bound: "Підключено",
    },

    result: {
      text: "Вітаємо! \nРезультат надіслано на твою пошту",
      Failure: "Ой, щось пішло не так.\nПеревір дані по оплаті, або звернись до банку.\n ",

      btn: "На головну",
    },
  },

  pageResult: {
    ticker: {
      intro: "Ти {{ role }}!",
      intro2: "Ти {{ role }}.",
      content: "ТВIЙ КОНТЕНТ",
      plan: "План дій",
      slogan: "Справжній вплив призводить до дії",
    },

    intro: {
      share: {
        title: "Розкажи друзям!",
        btn: "Завантажити вiдео",
      },
    },
    properties: {
      title: "Зрозумій себе",
    },

    congratulation: {
      title: "Тепер ти знаєш свою модель особистості.",

      description:
        "Біопсихосоціальну модель побудовано на: унікальних якостях твого характеру, особливостях нервової системи й набутому в соціумі досвіді. В тобі вже закладені усі перелічені риси, залишилося навчитися їх використовувати.\n\nТвоя модель бренду особистості найкраще пасує твоїй нервовій системі. Сміливо переходь до рекомендацій з контенту — вже без стресу. Контент — фундамент стратегії твого бренду. ",
    },

    model: {
      title: "Зараз ти дієш за моделлю {{ role }}",
    },

    plan: {
      description:
        "Ми створили для тебе план з 5 кроків за методологією <0>Fly_Academy</0>. Ти зможеш досягти поставленої мети і прискоритися на шляху до побудови бренду особистості.\nТеперь все в твоїх руках:",

      personal: {
        title: "Унікальна особиста пропозиція",
        description:
          "Надай чесні відповіді на питання нижче й сформуй унікальну особисту пропозицію, щоб рухатися у власному ритмі. Це основа твого бренду особистості, те, як люди сприйматимуть тебе.",

        question: [
          "Що я люблю робити?",
          "Що я роблю найкраще?",
          "Чого чекає моя аудиторія?",
        ],
      },
    },

    propose: {
      title: "Замов стратегію бренду особистості і отримай:",
      titleNew: 'Стратегія бренда особистості',
      description: 'Узнай как {{model}} может посторить влиятельный бренд личности без стресса',
      btn: "Оплатити",
      list: [
        "Готову стратегію побудови бренду особистості, зокрема гід з контенту. Тобі більше не доведеться платити гроші за курси і консультації, а головне - витрачати час.",
        "Відповідь, за якою моделлю ти дієш і що потрібно змінити й посилити, для досягнення поставлених цілей в найкоротші терміни.",
        "Структурований покроковий план, який виключить витрату зайвих ресурсів для досягнення результату.",
        "Інструмент для складання особистого унікального повідомлення, з яким ти вийдеш в публічний простір.",
      ],
      listPhone: [
        "Готову стратегію побудови бренду особистості, зокрема гід з контенту. Тобі більше не доведеться платити гроші за курси і консультації, а головне - витрачати час.",
        "Відповідь, за якою моделлю ти дієш і що потрібно змінити й посилити, для досягнення поставлених цілей в найкоротші терміни.",
        "Структурований покроковий план, який виключить витрату зайвих ресурсів для досягнення результату.",
        "Інструмент для складання особистого унікального повідомлення, з яким ти вийдеш в публічний простір.",
      ],
    },

    final: {
      title:
        "Перший крок зроблено. Тепер ти збудуєш бренд особистості за власними правилами. В цьому тобі допоможе «Книга бренду особистості — методичка, з якою ти самостійно і легко сформуєш свій бренд.",
      description:
        "Залишилися питання? Звернись за особистою консультацією в агенство з побудови бренду особистості",
      share: {
        hashtag: "#моямодельособистості",
      },
    },
  },
};

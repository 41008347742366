import {gql} from "@apollo/client";

export const ORDER_STATUS = gql`
    query useOrderStatus ($uid: String!){
        orderStatus(uid: $uid) {
            uid,
            price,
            provider,
            currency,
            link,
            status,
        }
    }
`
import React, {FC, memo, ReactNode, useState} from "react";
import classNames from 'classnames/bind';
import s from './index.module.scss';
import Ticker from "../../Landing/components/Ticker";
import {useTranslation} from "react-i18next";
import Slider from "../../Landing/components/Slider";
import {Maybe, RoleEnum} from "../../../api/generated";
import {ReactComponent as IconArrowRight} from "assets/icons/arrow_sm-right.svg";
import {ReactComponent as IconArrowLeft} from "../../../assets/icons/arrow_sm-left.svg";

const cx = classNames.bind(s)
const Yourself: FC<{
  isTablet: boolean,
  role?: Maybe<RoleEnum>
}> = ({isTablet, role}) => {
  const [t] = useTranslation()
  const [currentSlide, setCurrentSlide] = useState(0)
  const list = t(`yourself.${role?.toUpperCase()}.list`, {returnObjects: true}) as Array<string>
  const roleSplit = role?.split('_').join('-')
  const onClickNextSlide = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const onClickPrevSlide = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const firstBlock = (): ReactNode => {
    return (
      <div className={cx('Item', 'Frame')}>
        <div className={cx('ItemNumContainer')}>
          <span className={cx('ItemNum', 'Frame')}>
          <IconArrowRight/>
          </span>
        </div>
        <p>
          {t("pageResult.ticker.intro2", {role: roleSplit})} {" "}
          {t(`yourself.${role?.toUpperCase()}.title`, {role})}
        </p>
      </div>
    )
  }
  const contentItem = (text: string, index: number) => (
    <div className={cx("Item", 'Frame')}>
      <div className={cx('ItemNumContainer')}><span className={cx('ItemNum', 'Frame')}>
           {index ?? ""}
          </span>
      </div>
      <p dangerouslySetInnerHTML={{__html: text ?? ""}}/>
    </div>
  )
  return (
    <section className={cx('Component')}>
      <Ticker
        oneLine
        className={cx("Ticker")}
        message={t("yourself.ticker")}
      />
      <div className={cx("ContentContainer", "container")}>
        <div className={cx("Arrows")}>
          <button
            className={cx("BtnArrowPrev")}
            type="button"
            onClick={onClickPrevSlide}
          >
            <IconArrowLeft/>
          </button>
          <button
            className={cx("BtnArrowNext")}
            type="button"
            onClick={onClickNextSlide}
          >
            <IconArrowRight/>
          </button>
        </div>
      </div>
      <Slider
        dots={true}
        classNamesDots={cx('Dots')}
        autoHeight={true}
        className={cx("Slider")}
        slides={
          [firstBlock(),
            ...list?.map((text, i: number) => contentItem(text, i + 1))] ?? []
        }
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
    </section>
  )
}
export default memo(Yourself)

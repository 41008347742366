import React, {useEffect, useMemo, useState} from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import {useTranslation} from "react-i18next";
import {ReactComponent as IconInfo} from "assets/icons/info.svg";
import Button from "components/Button";
import Slider from "../components/Slider";
import {ROUTES} from "App";
import {useHistory} from "react-router-dom";

const cx = classNames.bind(styles);

const checkMobile = () => window.innerWidth < 768;

const Model = () => {
  const [t] = useTranslation();
  const [isMobile, setIsMobile] = useState(checkMobile());
  const history = useHistory();
  const goToTest = () => history.push(ROUTES.test);
  useEffect(() => {
    setIsMobile(checkMobile);
  }, []);
  const HowAreYou = () => <div className={cx("ModelItemAction")}>
    <div className={cx("ModelItemActionContent")}>
      <h4>{t("pageHome.model.you.name")}</h4>

      <Button onClick={goToTest} className={cx("ModelItemActionBtn")}>
        {t("pageHome.model.you.btn")}
      </Button>
    </div>
  </div>
  const models = useMemo(() => {
    return [
      <ModelItem
        name={t("pageHome.model.creator.name")}
        description={t("pageHome.model.creator.description")}
        image={require("assets/images/model-creator.png")}
        className={cx("bg-green")}
      />,
      <ModelItem
        name={t("pageHome.model.expert.name")}
        description={t("pageHome.model.expert.description")}
        image={require("assets/images/model-expert.png")}
        className={cx("bg-pink")}
      />,
      <ModelItem
        name={t("pageHome.model.insider.name")}
        description={t("pageHome.model.insider.description")}
        image={require("assets/images/model-insider.png")}
        className={cx("bg-purple")}
      />,
      <ModelItem
        name={t("pageHome.model.marketer.name")}
        description={t("pageHome.model.marketer.description")}
        image={require("assets/images/model-marketer.png")}
        className={cx("bg-green")}
      />,
      <ModelItem
        name={t("pageHome.model.lister.name")}
        description={t("pageHome.model.lister.description")}
        image={require("assets/images/model-a_lister.png")}
        className={cx("bg-pink")}
      />,
      !isMobile && HowAreYou()
    ];
  }, [t]);

  return (
    <section id="model" className={cx("Component")}>
      <div className={cx("container")}>
        <h2 className={cx("Title")}>{t("pageHome.model.title")}</h2>
        {!isMobile && <div className={cx("ModelList")}>{models}</div>}
      </div>
      {isMobile && <Slider slides={models}/>}
      <div className={cx("HowAreYou")}>
        {HowAreYou()}
      </div>
    </section>
  );
};

type ModelItemProps = {
  name: string;
  description: string;
  image: string;
  className: string;
};
const ModelItem = ({name, description, image, className}: ModelItemProps) => {
  const [show, setShow] = useState(false);
  return (
    <div onClick={() => setShow(!show)} className={cx("ModelItem", className)}>
      <img src={image} className={cx("ModelItemImg")} alt=""/>

      <h4 className={cx("ModelItemTitle")}>{name}</h4>
      <span className={cx('ModelItemInfoIcon')}>
        <IconInfo/>
      </span>

      <div
        className={cx("ModelItemDetails", show && "open")}
        onClick={() => setShow(false)}
      >
        <p className={cx("ModelItemDescription")}>{name} {'\n\n'}</p>
        <p className={cx("ModelItemDescription")}>{description}</p>
      </div>
    </div>
  );
};

export default Model;

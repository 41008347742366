import {ApolloProvider} from "@apollo/client";
import createClient from "api";
import {useAppContext} from "AppContext";
import CookiesAgreement from "components/Cookies";
import Footer from "components/Footer";
import PageBlogList from "pages/BlogList";
import PageError from "pages/Error";
import PageLanding from "pages/Landing";
import PageStatic from "pages/Static";
import PageBlog from "pages/Blog";
import React, {memo, useEffect, useMemo} from "react";
import {BrowserRouter as Router, Route, Switch, useLocation,} from "react-router-dom";
import Sidebar from "components/Sidebar";
import PageResultPay from "pages/ResultPay";
import PageResultPayment from "pages/ResultPayment";
import PageTest from "pages/Test";
import PageResult from "pages/Result";
import PageChoiceModel from 'pages/Promo';
import {SEARCH} from "./api/state";
import {Languages, setStoredLng} from "./locale";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

export const ROUTES = {
    home: "/",
    policy: "/policy",
    test: "/test",
    blogs: "/blogs",
    promo: () => `/promo/`,
    result: (uid: string | number = ":uid") => `/result/${uid}`,
    resultPay: (uid: string | number = ":uid") => `/result/${uid}/pay`,
    resultShare: (uid: string | number = ":uid") => `/result/${uid}/share`,
    resultPayment: (uid: string | number = ":uid", order_uid: string | number = ":order_uid") => `/result/${uid}/${order_uid}/payment`,
    blog: (slug: string | number = ":slug") => `/blog/${slug}`,
};


const PageController = memo(() => {
    const {pathname, search} = useLocation();
    const {i18n} = useTranslation();
    const searchParams = new URLSearchParams(search);
    const lang = searchParams.get("lang");

    const changeLng = (lng: Languages) => {
        setStoredLng(lng);
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual";
        }
        SEARCH(search)

        if (lang && ['uk', 'ru', 'en'].includes(lang)) {
            changeLng(lang as Languages);
        }

    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
});

function App() {
    const {changeErrorCode} = useAppContext();
    const client = useMemo(() => {
        return createClient({onErrorReceived: changeErrorCode});
    }, []);

    // const testing = localStorage.getItem("testing") ?? 'false'
    // if (!JSON.parse(testing)) {
    //   return <div>
    //     <PageError isTechnicalWork
    //                description={'pageError.isTechnical'}
    //     />
    //   </div>
    // }
    return (
        <ApolloProvider client={client}>
            <Router>
                <Switch>
                    <Route path={ROUTES.home} exact component={PageLanding}/>
                    <Route path={ROUTES.policy} component={PageStatic}/>
                    <Route path={ROUTES.test} component={PageTest}/>
                    <Route path={ROUTES.blogs} component={PageBlogList}/>
                    {/*<Route path={ROUTES.promo()} component={PageChoiceModel}/>*/}
                    <Route path={ROUTES.resultPay()} component={PageResultPay}/>
                    <Route path={ROUTES.resultShare()} component={PageLanding}/>
                    <Route path={ROUTES.resultPayment()} component={PageResultPayment}/>
                    <Route path={ROUTES.result()} component={PageResult}/>
                    <Route path={ROUTES.blog()} component={PageBlog}/>
                    <Route path="*" component={PageError}/>
                </Switch>
                <PageController/>
                <CookiesAgreement/>
                <Sidebar/>
                <Route
                    path="*"
                    render={({location: {pathname}}) =>
                        (pathname !== ROUTES.test && !pathname.includes('/payment'))
                        && <Footer/>
                    }
                />
            </Router>
        </ApolloProvider>
    );
}

export default App;

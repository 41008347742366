import React, {FC, memo, useEffect, useRef, useState} from 'react'

type PropsType = {
  src: string,
  imagePath?: string,
  classNames?: string
}
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0
}


const AutoplayVideo: FC<PropsType> = (props): JSX.Element => {
  const videoParentRef = useRef<any>()
  const [shouldUseImage, setShouldUseImage] = useState<boolean>(false)
  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current?.children[0];
      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", "")
        player.autoplay = true;
        setTimeout(() => {
          const promise = player.play()
          if (promise.then) {
            promise
              .then(() => {
              })
              .catch(() => {
                videoParentRef.current.style.display = "none"
                setShouldUseImage(true)
              })
          }
        }, 0)
      }
    }
  }, [])
  return shouldUseImage ? (<img src={props.imagePath} alt=""/>) : <div
    dangerouslySetInnerHTML={{
      __html: `
      <video
        loop
        muted 
        autoplay
        playsinline
        class="${props.classNames}"
        >
         <source src="${props.src}" type="video/mp4" />
        </video>
`
    }}
  />
}
export default memo(AutoplayVideo)
import React, {useEffect, useState} from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import {useTranslation} from "react-i18next";
import Button from "components/Button";
import useField from "hooks/useField";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin, {
  GoogleLoginProps,
  GoogleLoginResponse,
} from "react-google-login";
import {
  GOOGLE_CLIENT_ID,
  FACEBOOK_CLIENT_KEY,
  INSTAGRAM_APP_ID,
} from "constants/constants";
import {ProfileData, SURVEY_DATA_KEY} from "pages/Test";
import {
  SocialNetworksMutation,
  useSocialNetworksMutation,
} from "api/generated";
import SocialLogin from "react-social-login";
import {parseError} from "api";

const cx = classNames.bind(styles);

type Props = {
  profileData: ProfileData;
  suid: string;
  onSuccess(question: SocialNetworksMutation): void;
};

class SB extends React.Component<any> {
  render() {
    return (
      <button onClick={this.props.triggerLogin} {...this.props}>
        {this.props.children}
      </button>
    );
  }
}

const SocialButton = SocialLogin(SB);

const QuestionSocial = ({profileData, suid, onSuccess}: Props) => {
  const [error, setError] = useState("");
  const [t, {language: lang}] = useTranslation();

  const [socialNetworksMutation, {loading}] = useSocialNetworksMutation();

  const telegramLink = useField("");
  const tiktokLink = useField("");
  const twitterLink = useField("");
  const youtubeLink = useField("");
  const instagramLink = useField("");
  const facebookLink = useField("");

  const instagramToken = useField("");
  const facebookToken = useField(profileData.facebookToken ?? "");
  const googleToken = useField(profileData.googleToken ?? "");

  const onFacebookCallback = (fbResponse: any) => {
    facebookToken.change(fbResponse.accessToken);
  };

  const onSucccesGoogleResponse: GoogleLoginProps["onSuccess"] = (data) => {
    if (data.code) return;
    data = data as GoogleLoginResponse;
    googleToken.change(data.accessToken);
  };

  const values = [
    telegramLink.value,
    tiktokLink.value,
    twitterLink.value,
    youtubeLink.value,
    instagramLink.value,
    facebookLink.value,
    instagramToken.value,
    facebookToken.value,
    googleToken.value,
  ];

  /** Get data from cache */
  useEffect(() => {
    const storedSurvey = sessionStorage.getItem(SURVEY_DATA_KEY);
    if (storedSurvey) {
      try {
        const parsedStoredSurvey = JSON.parse(storedSurvey);
        if (parsedStoredSurvey.socialFields) {
          telegramLink.change(
            parsedStoredSurvey.socialFields.telegramLink || telegramLink.value
          );
          tiktokLink.change(
            parsedStoredSurvey.socialFields.tiktokLink || tiktokLink.value
          );
          twitterLink.change(
            parsedStoredSurvey.socialFields.twitterLink || twitterLink.value
          );
          youtubeLink.change(
            parsedStoredSurvey.socialFields.youtubeLink || youtubeLink.value
          );
          instagramLink.change(
            parsedStoredSurvey.socialFields.instagramLink || instagramLink.value
          );
          facebookLink.change(
            parsedStoredSurvey.socialFields.facebookLink || facebookLink.value
          );
          instagramToken.change(
            parsedStoredSurvey.socialFields.instagramToken
            || instagramToken.value
          );
          facebookToken.change(
            parsedStoredSurvey.socialFields.facebookToken
            || facebookToken.value
          );
          googleToken.change(
            parsedStoredSurvey.socialFields.googleToken || googleToken.value
          );
        }
      } catch {
      }
    }
  }, []);

  /** Save data to cache */
  useEffect(() => {
    const storedSurvey = sessionStorage.getItem(SURVEY_DATA_KEY);
    if (storedSurvey) {
      try {
        const newStoredSurvey = JSON.parse(storedSurvey);
        newStoredSurvey.socialFields = {
          ...newStoredSurvey.socialFields,
          telegramLink: telegramLink.value,
          tiktokLink: tiktokLink.value,
          twitterLink: twitterLink.value,
          youtubeLink: youtubeLink.value,
          instagramLink: instagramLink.value,
          facebookLink: facebookLink.value,
          instagramToken: instagramToken.value,
          facebookToken: facebookToken.value,
          googleToken: googleToken.value,
        };

        sessionStorage.setItem(
          SURVEY_DATA_KEY,
          JSON.stringify(newStoredSurvey)
        );
      } catch {
        sessionStorage.removeItem(SURVEY_DATA_KEY);
      }
    }
  }, values);

  const onSubmit = async () => {
    telegramLink.changeError("");
    tiktokLink.changeError("");
    twitterLink.changeError("");
    youtubeLink.changeError("");
    instagramLink.changeError("");
    facebookLink.changeError("");
    instagramToken.changeError("");
    facebookToken.changeError("");
    googleToken.changeError("");
    setError("");

    if (!values.some(Boolean)) {
      return setError(t("error.fillAtLeastOneField"));
    }

    const onErrorReceived = (msg?: string) => {
      setError(`${t("error.globalError")}${msg ? `: ${msg}` : ""}`);
    };

    try {
      const {data} = await socialNetworksMutation({
        variables: {
          lang,
          suid,
          telegramLink: telegramLink.value || undefined,
          tiktokLink: tiktokLink.value || undefined,
          twitterLink: twitterLink.value || undefined,

          googleToken: googleToken.value || undefined,
          youtubeLink:
            googleToken.value || !youtubeLink.value
              ? undefined
              : youtubeLink.value,

          instagramToken: instagramToken.value || undefined,
          instagramLink:
            instagramToken.value || !instagramLink.value
              ? undefined
              : instagramLink.value,

          facebookToken: facebookToken.value || undefined,
          facebookLink:
            facebookToken.value || !facebookLink.value
              ? undefined
              : facebookLink.value,
        },
      });
      onSuccess(data!);
    } catch (e) {
      onErrorReceived(parseError(e).message);
    }
  };

  const onInstagramLoginSuccess = (data: any) => {
  };

  const onInstagramLoginFailure = (data: any) => {
  };

  const BoundMsg = (
    <span className={cx("BoundMsg")}>
      <img src={require("assets/icons/success.svg")} alt=""/>
      {t("pageTest.socialQuestion.bound")}
    </span>
  );

  return (
    <div className={cx("Component")}>
      <h2 className={cx("Title")}>{t("pageTest.socialQuestion.title")}</h2>
      <p className={cx("Description")}>
        {t("pageTest.socialQuestion.description")}
      </p>

      <div className={cx("FieldGroup")}>
        <div className={cx("Field")}>
          {facebookToken.value ? (
            BoundMsg
          ) : (
            <input
              placeholder="Facebook"
              readOnly={loading}
              className={cx("FieldInput")}
              value={facebookLink.value}
              onChange={(e) => facebookLink.change(e.target.value)}
              type="text"
            />
          )}

          <FacebookLogin
            appId={FACEBOOK_CLIENT_KEY}
            fields="name,email"
            callback={onFacebookCallback}
            isMobile={true}
            disableMobileRedirect={true}
            render={(renderProps: JSX.IntrinsicElements["input"]) => (
              <span
                className={cx("FieldIcon")}
                {...renderProps}
                onClick={
                  facebookToken.value || loading
                    ? undefined
                    : renderProps.onClick
                }
              >
                <img src={require("assets/icons/social_fb.svg")} alt=""/>
              </span>
            )}
          />
        </div>

        <div className={cx("Field")}>
          <input
            placeholder="Instagram"
            readOnly={loading}
            className={cx("FieldInput")}
            value={instagramLink.value}
            onChange={(e) => instagramLink.change(e.target.value)}
            type="text"
          />
          <span className={cx("FieldIcon")}>

          {/*<SocialButton*/}
          {/*  appId={"1734520813379609"}*/}
          {/*  // appId={INSTAGRAM_APP_ID}*/}
          {/*  autoCleanUri*/}
          {/*  scope={"user_profile"}*/}
          {/*  className={cx("FieldIcon")}*/}
          {/*  redirect={window.location.href}*/}
          {/*  provider="instagram"*/}
          {/*  onLoginSuccess={onInstagramLoginSuccess}*/}
          {/*  onLoginFailure={onInstagramLoginFailure}*/}
          {/*>*/}
            <img src={require("assets/icons/social_instagram.svg")} alt=""/>
          {/*</SocialButton>*/}
          </span>
        </div>

        <div className={cx("Field")}>
          {googleToken.value ? (
            BoundMsg
          ) : (
            <input
              placeholder="YouTube"
              className={cx("FieldInput")}
              readOnly={loading}
              value={youtubeLink.value}
              onChange={(e) => youtubeLink.change(e.target.value)}
              type="text"
            />
          )}

          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            cookiePolicy={"single_host_origin"}
            onSuccess={onSucccesGoogleResponse}
            render={(renderProps) => (
              <span
                className={cx("FieldIcon")}
                {...renderProps}
                onClick={
                  googleToken.value || loading ? undefined : renderProps.onClick
                }
              >
                <img src={require("assets/icons/social_youtube.svg")} alt=""/>
              </span>
            )}
          />
        </div>
        <div className={cx("Field")}>
          <input
            readOnly={loading}
            placeholder="TikTok"
            value={tiktokLink.value}
            onChange={({target: {value}}) => tiktokLink.change(value)}
            className={cx("FieldInput")}
            type="text"
          />
          <span className={cx("FieldIcon")}>
            <img src={require("assets/icons/social_tiktok.svg")} alt=""/>
          </span>
        </div>
        <div className={cx("Field")}>
          <input
            readOnly={loading}
            placeholder="Telegram"
            className={cx("FieldInput")}
            value={telegramLink.value}
            onChange={({target: {value}}) => telegramLink.change(value)}
            type="text"
          />
          <span className={cx("FieldIcon")}>
            <img src={require("assets/icons/social_telegram.svg")} alt=""/>
          </span>
        </div>
        <div className={cx("Field")}>
          <input
            readOnly={loading}
            placeholder="Twitter"
            value={twitterLink.value}
            onChange={({target: {value}}) => twitterLink.change(value)}
            className={cx("FieldInput")}
            type="text"
          />
          <span className={cx("FieldIcon")}>
            <img src={require("assets/icons/social_twitter.svg")} alt=""/>
          </span>
        </div>
      </div>

      <p className={cx("FormError")}>{error}</p>

      <div className={cx("SubmitRow")}>
        <Button onClick={onSubmit} loading={loading} type="button">
          {t("pageTest.socialQuestion.submit")}
        </Button>
      </div>
    </div>
  );
};

export default QuestionSocial;

import {ReactComponent as IconArrowLeft} from "assets/icons/arrow_sm-left.svg";
import {ReactComponent as IconArrowRight} from "assets/icons/arrow_sm-right.svg";
import classNames from "classnames/bind";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import Slider from "../components/Slider";
import styles from "./index.module.scss";
import Button from "../../../components/Button";
import {ROUTES} from "../../../App";
import {useHistory} from "react-router-dom";

const cx = classNames.bind(styles);

const ReviewVideo = ({index}: { index: number }) => {
  const ref = useRef<HTMLVideoElement>(null);
  const [t, {language}] = useTranslation();
  const [isPlay, setIsPlay] = useState(false);

  const onClick = () => {
    if (isPlay) {
      ref.current?.pause();
    } else {
      ref.current?.play();
    }
    setIsPlay(!isPlay);
  };

  return (
    <div className={cx("ReviewVideo")}>
      <button onClick={onClick} className={cx("ReviewVideoBtn")}>
        {!isPlay && <img src={require("assets/icons/play.svg")} alt="Play"/>}
      </button>
      <video
        preload="metadata"
        poster={`${window.location.origin}/assets/video/review_${index}_${language}.png`}
        ref={ref}
        controls={false}
        src={`${window.location.origin}/assets/video/review_${index}_${language}.mp4`}
      />
    </div>
  );
};

const Participants = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [t] = useTranslation();
  const {push} = useHistory()
  const goToTest = () => push(ROUTES.test);

  const onClickNextSlide = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const onClickPrevSlide = () => {
    setCurrentSlide(currentSlide - 1);
  };

  return (
    <section id="participants" className={cx("Component")}>
      <header className={cx("Header", "container")}>
        <h2 className={cx("Title")}>{t("pageHome.participants.title")}</h2>
        <div className={cx("SliderArrows")}>
          <button
            className={cx("SlideBtnArrowPrev")}
            type="button"
            onClick={onClickPrevSlide}
          >
            <IconArrowLeft/>
          </button>
          <button
            className={cx("SlideBtnArrowNext")}
            type="button"
            onClick={onClickNextSlide}
          >
            <IconArrowRight/>
          </button>
        </div>
      </header>

      <Slider
        slides={[
          <ReviewVideo index={1}/>,
          <ReviewVideo index={2}/>,
          <ReviewVideo index={3}/>,
          <ReviewVideo index={4}/>,
          <ReviewVideo index={5}/>,
        ]}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
      <Button
        onClick={goToTest} withArrow arrowDirection={'right'}
        className={cx('Btn')}>{t('sidebar.startTest')}</Button>
    </section>
  );
};

export default Participants;

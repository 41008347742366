import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import useField from "hooks/useField";
import useDropdown from "hooks/useDropdown";
const cx = classNames.bind(styles);

type Props = {
  text: string;
  options: string[];
  onSubmit: (text: string) => void;
};

const QuestionSelect = ({ text, options, onSubmit }: Props) => {
  const [t] = useTranslation();
  const listRef = useRef<HTMLDivElement>(null);
  const dropdown = useDropdown();
  const field = useField("");

  const onClickSubmit = () => {
    field.changeError("");

    if (!field.value) {
      return field.changeError(t("error.requiredField"));
    }

    onSubmit(field.value);
  };

  const updateListHeight = () => {
    if (!listRef.current) return;
    const bottomOffset = 24;
    const heightProposal =
      window.innerHeight -
      listRef.current.getBoundingClientRect().top -
      bottomOffset;
    listRef.current.style.maxHeight = `${heightProposal}px`;
  };

  useEffect(() => {
    window.onresize = updateListHeight;

    return () => {
      window.onresize = null;
    };
  }, []);

  const toggleDropdown = () => {
    /** dropdown opens. need to calc height */
    if (!dropdown.open) {
      updateListHeight();
    }

    dropdown.toggle();
  };

  return (
    <div className={cx("Component")}>
      <div>
        <p className={cx("Text")}>{text}</p>
        <div
          ref={dropdown.ref}
          className={cx(
            "Field",
            !!field.error && "error",
            dropdown.open && "open"
          )}
        >
          <div className={cx("FieldInputWrapper")}>
            <input
              onClick={toggleDropdown}
              placeholder={t('pageTest.field.placeholder')}
              className={cx("FieldInput")}
              type="text"
              readOnly
              value={field.value}
            />
            <img
              className={cx("FieldIcon")}
              src={require("assets/icons/angle.svg")}
              alt="Angle"
            />
            <div ref={listRef} className={cx("FieldOptions")}>
              {options.map((option, i) => (
                <button
                  key={`${option}-${i}`}
                  type="button"
                  onClick={() => {
                    field.change(option);
                    dropdown.toggle();
                  }}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
          <p className={cx("FieldError")}>{field.error}</p>
        </div>
      </div>

      <div className={cx("Actions", dropdown.open && "hide")}>
        <Button onClick={onClickSubmit}>{t("pageTest.actions.next")}</Button>
      </div>
    </div>
  );
};

export default QuestionSelect;

import { useState, useRef, useEffect } from 'react'

type UseDropdown = {
	onClose?(): void
}

/**
 *
 * @description
 * Helpful hook to craete fully contoled dropdown component
 * 1) pass `ref` to dropdown wrapper
 * 2) use `toggle` or `setOpen` actions to control visibility
 * 3) use `open` flag to display or hide dropdown's content
 * 4) for better content position use `popperRef` to select
 * dropdown content and `popper` object to pass correct position
 * @example
 * const dropdown = useDropdown()
 *
 * <div ref={dropdown.ref}>
 *     <button onClick={dropdown.toggle}>toggle content</div>
 *
 *     {dropdown.open && (
 *         <div
 *             ref={dropdown.popperRef}
 *             style={dropdown.popper.styles.popper}
 *             {...dropdown.popper.attributes.popper}
 *         >
 *             content
 *         </div>
 *     )}
 * </div>
 */
function useDropdown({ onClose }: UseDropdown = {}) {
	const [open, setOpen] = useState(false)
	const ref = useRef<HTMLDivElement>(null)

	const toggle = () => setOpen(!open)

	useEffect(() => {
		const mousedownListener = (e: MouseEvent) => {
			if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
				document.removeEventListener('mousedown', mousedownListener)
				setOpen(false)
			}
		}

		const keydownListener = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				setOpen(false)
				e.preventDefault()
				e.stopPropagation()
			}
		}

		if (open) {
			document.addEventListener('mousedown', mousedownListener)
			document.addEventListener('keydown', keydownListener)
		} else {
			document.removeEventListener('mousedown', mousedownListener)
			document.removeEventListener('keydown', keydownListener)
			onClose?.()
		}

		return () => {
			document.removeEventListener('keydown', keydownListener)
			document.removeEventListener('mousedown', mousedownListener)
		}
	}, [open])

	return {
		open,
		toggle,
		ref,
		setOpen,
	}
}

export default useDropdown

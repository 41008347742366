import {usePostQuery} from "api/generated";
import {ROUTES} from "App";
import IconArrow from "assets/icons/arrow_sm-left.svg";
import {ReactComponent as IconFB} from "assets/icons/fb.svg";
import {ReactComponent as IconTwitter} from "assets/icons/twitter.svg";
import classNames from "classnames/bind";
import Preloader from "components/Preloader";
import {SidebarControl} from "components/Sidebar";
import AdsBlock from "pages/BlogList/AdsBlock";
import PageError from "pages/Error";
import React, {useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import {useTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router-dom";
import {FacebookShareButton, TwitterShareButton} from "react-share";
import styles from "./index.module.scss";

const cx = classNames.bind(styles);

const PageBlog = ({
                    history: {goBack, ...history},
                    match: {
                      params: {slug},
                    },
                  }: RouteComponentProps<{ slug: string }>) => {
  const [_, {language: lang}] = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLSpanElement>(null);
  const postQuery = usePostQuery({variables: {lang, slug}});
  const goToTest = () => history.push(ROUTES.test);

  useEffect(() => {
    window.onscroll = () => {
      const contentHeight = pageRef.current?.offsetHeight ?? 0;
      const scrollY = window.scrollY + window.innerHeight;
      let progress = scrollY / contentHeight;
      if (progress > 1) progress = 1;
      if (progressRef.current)
        progressRef.current.style.width = `${progress * 100}%`;
    };
  }, []);

  /** insert ads to the middle of post */
  useEffect(() => {
    const content = contentRef.current;
    if (content) {
      const items = content.childNodes;

      let middle = parseInt(`${items.length / 2}`);

      /**
       * in common cases if element is not `p` this is h2/h3/ul/ol
       * that have not be divided by ads with previous description block
       */
      if ((items[middle] as any).tagName !== "p") {
        middle += 1;
      }

      const adsPortal = document.createElement("div");
      items[middle].insertBefore(adsPortal, null);
      ReactDOM.render(
        <AdsBlock goToTest={goToTest} className={cx("AdsBlock")}/>,
        adsPortal
      );
    }
  }, [postQuery.data?.post?.text]);

  if (postQuery.loading) return <Preloader/>;
  if (postQuery.error) return <PageError/>;

  return (
    <div ref={pageRef} className={cx("Page")}>
      <div className={cx("Progress")}>
        <span ref={progressRef}/>
      </div>

      <div className="container">
        <header className={cx("Header")}>
          <button onClick={goBack}>
            <img src={IconArrow}/>
          </button>
          <SidebarControl/>
        </header>

        <main className={cx("Content")}>
          <img
            className={cx("PrimaryImg")}
            src={postQuery.data?.post?.photo ?? ""}
            alt=""
          />

          <h1 className={cx("Title")}>{postQuery.data?.post?.title}</h1>

          <div className={cx("SocialLinks")}>
            <div className={cx("SocialLinksList")}>
              <FacebookShareButton
                url={window.location.href}
                quote={postQuery.data?.post?.title!}
                hashtag="#personalbrandmodel"
              >
                <IconFB/>
              </FacebookShareButton>
              <TwitterShareButton
                title={postQuery.data?.post?.title!}
                url={window.location.href}
                hashtags={["#personalbrandmodel"]}
              >
                <IconTwitter/>
              </TwitterShareButton>
            </div>
          </div>

          <div
            ref={contentRef}
            dangerouslySetInnerHTML={{
              __html: postQuery.data?.post?.text ?? "",
            }}
          />
        </main>
      </div>
    </div>
  );
};

export default PageBlog;

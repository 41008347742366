import React, {Fragment, useEffect, useState} from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import {useTranslation} from "react-i18next";
import QuestionBoolean from "./QuestionBoolean";
import QuestionSelect from "./QuestionSelect";
import {
  Mutation,
  MutationPreviousAnswerArgs,
  PrevAnswerQuery,
  SetAnswerMutationVariables,
  SocialNetworksMutation,
  TypeEnum,
  usePrevAnswerQuery,
  useSetAnswerMutation,
} from "api/generated";
import {ProfileData, SURVEY_DATA_KEY, SurveyType} from "..";
import {parseError} from "api";
import QuestionSocial from "./QuestionSocial";
import {useMutation} from "@apollo/client";
import {PREVIOUS_ANSWER} from "../../../api/mutations/PREVIOUS_ANSWER";

const cx = classNames.bind(styles);

export type Props = {
  survey: SurveyType;
  profileData: ProfileData;
  onAnswerLastQuestion: () => void;
  initialProgress: {
    total: number;
    num: number;
  };
};

export type QuestionType = NonNullable<NonNullable<PrevAnswerQuery["prevAnswer"]>["survey"]>["currentQuestion"];

const Survey = ({
                  survey,
                  profileData,
                  onAnswerLastQuestion,
                  initialProgress = {total: 1, num: 0},
                }: Props) => {
  const [t, {language: lang}] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(initialProgress);
  const [error, setError] = useState("");
  const [question, setQuestion] = useState<QuestionType>(
    survey?.currentQuestion
  );

  useEffect(() => {
    sessionStorage.removeItem(SURVEY_DATA_KEY);
    return () => {
      sessionStorage.removeItem(SURVEY_DATA_KEY);
    };
  }, []);

  const prevQuestionQuery = usePrevAnswerQuery({skip: true});
  const [prevAnswer] = useMutation<Mutation, MutationPreviousAnswerArgs>(PREVIOUS_ANSWER)

  const onErrorReceived = (msg?: string) => {
    setError(`${t("error.globalError")}${msg ? `: ${msg}` : ""}`);
  };

  const [
    setAnswerMutation,
    {loading: loadingSetAnswer},
  ] = useSetAnswerMutation();

  const getPrevQuestion = () => {
    setError("");
    setLoading(true);
    prevAnswer({
      variables: {
        surveyUid: survey!.uid,
        lang,
        questionId: question?.id as any
      }
    })
      .then(({data}) => {
        if (!data) return;
        if (data.previousAnswer?.answer?.survey?.currentQuestion) {
          setProgress({
            total: data.previousAnswer?.answer?.survey.questionsTotal ?? progress.total,
            num: data.previousAnswer?.answer?.num ?? progress.num,
          });
          setQuestion(data.previousAnswer?.answer?.survey?.currentQuestion);
        } else {
          onErrorReceived();
        }
        setLoading(false);
      })
      .catch((e) => {

      })
  };

  const isBooleanQuestion = [
    TypeEnum.ExtroIntro,
    TypeEnum.Neurotism,
    TypeEnum.Category,
    TypeEnum.Clarify,
  ].includes(question?.questionEnumType!);

  const onAnswerQuestion = (question: QuestionType) => {
    if (question) {
      setQuestion(question);
    } else {
      onAnswerLastQuestion();
    }
  };

  const onSubmitQuestion = (
    variables: Pick<SetAnswerMutationVariables, "text" | "yesno">
  ) => {
    setError("");

    if (loadingSetAnswer) return;

    setAnswerMutation({
      variables: {
        lang,
        questionId: Number(question?.id),
        suid: survey?.uid!,
        ...variables,
      }
    })
      .then(({data}) => {
        setProgress({
          total:
            data?.setAnswer?.answer?.survey?.questionsTotal ?? progress.total,
          num: data?.setAnswer?.answer?.num ?? progress.num,
        });
        // @ts-ignore
        // const currentQuestion = {...data?.setAnswer?.answer?.survey?.currentQuestion} as QuestionType
        const currentQuestion = {...data?.setAnswer?.answer?.question, choices: data?.setAnswer?.answer?.choices} as any
        // @ts-ignore
        onAnswerQuestion(currentQuestion);
      })
      .catch((e) => {
        onErrorReceived(parseError(e).message);
      });
  };

  const onAnswerSocialQuestion = (data: SocialNetworksMutation) => {
    setProgress({
      total:
        data?.socialNetworks?.answer?.survey?.questionsTotal ?? progress.total,
      num: data?.socialNetworks?.answer?.num ?? progress.num,
    });
    onAnswerQuestion(data.socialNetworks?.answer?.survey?.currentQuestion);
  };

  let progressPercent = ((progress?.num ?? 0) / (progress?.total ?? 1)) * 100;
  if (progressPercent >= 100) progressPercent = 100;

  return (
    <div className={cx("Component")}>
      <div className={cx("Progress")}>
        <div
          style={{
            width: `${progressPercent}%`,

          }}
        />
      </div>
      <div className={cx("Content", "container")}>
        <div className={cx("ActionsPanel")}>
          {progress?.num > 1 && (
            <button
              onClick={getPrevQuestion}
              disabled={loading || loadingSetAnswer}
              className={cx("BtnBack")}
            >
              <img src={require("assets/icons/arrow-right.svg")} alt=""/>
              <span>{t("pageTest.actions.back")}</span>
            </button>
          )}
        </div>

        <div className={cx("Main")}>
          {loadingSetAnswer || loading ? (
            <img className={cx("Loader")} src="/assets/loader.gif" alt=""/>
          ) : (

            <Fragment>
              {question?.questionEnumType === TypeEnum.SocialNetworks ? (
                <QuestionSocial
                  onSuccess={onAnswerSocialQuestion}
                  profileData={profileData}
                  suid={survey?.uid}
                />
              ) : isBooleanQuestion ? (
                <QuestionBoolean
                  text={question?.text ?? ""}
                  onSubmit={(yesno) => onSubmitQuestion({yesno})}
                />
              ) : (
                <QuestionSelect
                  text={question?.text ?? ""}
                  options={
                    question?.choices?.map((option) => option?.text ?? "") ?? []
                  }
                  onSubmit={(text) => onSubmitQuestion({text})}
                />
              )}
            </Fragment>
          )}
          <p className={cx("FormError")}>{error}</p>
        </div>
      </div>
    </div>
  );
};

export default Survey;

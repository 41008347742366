import React, {FormEvent, Fragment, ReactNode, useState} from "react";
import {GoogleLogin, GoogleLoginProps, GoogleLoginResponse,} from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import {useTranslation} from "react-i18next";
import useField from "hooks/useField";
import Button from "components/Button";
import {StartSurveyMutationVariables, useFinishSurveyMutation, useStartSurveyMutation,} from "api/generated";
import {parseError} from "api";
import {ProfileData, Step, SurveyType} from "..";
import {FACEBOOK_CLIENT_KEY, GOOGLE_CLIENT_ID} from "constants/constants";
import {ReactFacebookLoginInfo} from "react-facebook-login";
import {useReactiveVar} from "@apollo/client";
import {CodeQueryURL, SEARCH} from "../../../api/state";
import {useLocation} from "react-router-dom";
import {getCookie} from "../../../utils/getCookie";

const cx = classNames.bind(styles);

export type Props = {
  onSubmitRegistration: (survey: SurveyType, profileData: ProfileData) => void;
  profileData: ProfileData;
  step: Step;
  suid?: undefined;
};

const Registration = ({
                        onSubmitRegistration,
                        step,
                        profileData,
                        suid,
                      }: Props) => {
  const [t, {language: lang}] = useTranslation();
  const [error, setError] = useState("");
  const firstName = useField(profileData.firstName || "");
  const lastName = useField(profileData.lastName || "");
  const age = useField(profileData.age || "");
  const email = useField(profileData.email || "");
  const [googleToken, setGoogleToken] = useState<string | undefined>();
  const [facebookToken, setFacebookToken] = useState<undefined | string>();
  const agreeTerms = useField(true);
  const agreeMailing = useField(true);
  const codeVar = useReactiveVar(CodeQueryURL)
  const search = useReactiveVar(SEARCH)
  const isFinishing = step === "final-registration";
  const cookie = getCookie('_ga') ?? ''

  const [
    startSurveyMutation,
    {loading: loadingStartSurvey},
  ] = useStartSurveyMutation();
  const [
    finishSurveyMutation,
    {loading: loadingFinishSurvey},
  ] = useFinishSurveyMutation();

  const onSubmit = (
    e?: FormEvent,
    variables?: StartSurveyMutationVariables
  ) => {
    e?.preventDefault();
    firstName.changeError("");
    email.changeError("");
    setError("");

    let invalid = false;

    if (!firstName?.value?.trim() && !variables?.firstName?.trim()) {
      invalid = true;
      firstName.changeError(t("error.requiredField"));
    }

    if (!email?.value?.trim() && !variables?.email?.trim()) {
      invalid = true;
      email.changeError(t("error.requiredField"));
    }

    if (isFinishing) {
      if (!age.value.trim()) {
        invalid = true;
        age.changeError(t("error.requiredField"));
      }
      if (!lastName?.value?.trim()) {
        invalid = true;
        lastName.changeError(t("error.requiredField"));
      }
      if (!agreeTerms?.value) {
        invalid = true;
        agreeTerms.changeError(
          t("error.requiredField", {context: "checkbox"})
        );
      }
    }

    if (invalid) return;

    if (isFinishing) {
      finishSurveyMutation({
        variables: {
          additionalData: search ?? '',
          firstName: firstName.value,
          lastName: lastName.value,
          age: Number(age.value),
          email: email.value,
          suid,
          lang,
          ga: cookie
        },
      })
        .then(({data}) => {
          // all ok - start test
          if (data?.finishSurvey) {
            onSubmitRegistration(data.finishSurvey.survey, {
              firstName: firstName.value,
              lastName: lastName.value,
              email: email.value,
              googleToken,
              facebookToken,
              suid
            } as any);
          } else {
            setError(t("error.globalError"));
          }
        })
        .catch((e) => {
          const {message} = parseError(e);
          message ? setError(`${message}`) : setError(`${t("error.globalError")}`)
        });
    } else {
      const profileData = {
        firstName: firstName.value,
        email: email.value,
        googleToken,
        lang,
        facebookToken,
        additionalData: search ?? '',
        ga: cookie,
        ...variables,
      } as any;
      if (codeVar) {
        profileData.code = codeVar
      }
      startSurveyMutation({
        variables: profileData,
      })
        .then(({data}) => {
          // all ok - start test
          if (data?.startSurvey) {
            onSubmitRegistration(data.startSurvey.survey, profileData);
          } else {
            setError(t("error.globalError"));
          }
        })
        .catch((e) => {
          const {message} = parseError(e);
          message ? setError(`${message}`) : setError(`${t("error.globalError")}`);
        });
    }
  };

  const onFacebookCallback = (fbResponse?: Pick<ReactFacebookLoginInfo, "name" | "accessToken" | "email">) => {
    // @ts-ignore
    FB.getAuthResponse(function (response: any) {
    });
    if (!fbResponse) {
      setError(t("error.globalError"));
      return;
    }
    let {
      name,
      accessToken,
      email: fbEmail
    } = fbResponse as Pick<ReactFacebookLoginInfo, "name" | "accessToken" | "email">

    // getPermission()
    name = name?.split(" ")[0] as string;
    lastName.change(name?.split(" ")[1])
    setFacebookToken(accessToken);
    firstName.change(name);
    email.change(fbEmail as string);
    onSubmit(undefined, {
      email: fbEmail, firstName: name, facebookToken
    } as any);
  };

  const onSucccesGoogleResponse: GoogleLoginProps["onSuccess"] = (data) => {
    if (data.code) return;
    data = data as GoogleLoginResponse;
    setGoogleToken(data.accessToken);
    firstName.change(data.profileObj.givenName);
    lastName.change(data.profileObj.familyName);
    email.change(data.profileObj.email);
    onSubmit(undefined, {
      email: data.profileObj.email,
      firstName: data.profileObj.givenName,
      googleToken: data.accessToken,
    });
  };

  const FieldEmail = (
    <TextField
      value={email.value}
      error={email.error}
      onChange={({target: {value}}) => email.change(value)}
      placeholder={t("pageTest.registration.email")}
      containerClassName={cx("FormRowField")}
    />
  );

  return (
    <div className={cx("Component", step)}>
      <form onSubmit={onSubmit} className={cx("Form", "container")}>
        <h2 className={cx("Title")}>
          {t("pageTest.registration.title", {context: step})}
        </h2>
        <p className={cx("Description")}>
          {t("pageTest.registration.description", {context: step})}
        </p>

        <div className={cx("FormRow")}>
          <TextField
            value={firstName.value}
            error={firstName.error}
            onChange={({target: {value}}) => firstName.change(value)}
            placeholder={t("pageTest.registration.name")}
            containerClassName={cx("FormRowField")}
          />
          {isFinishing ? (
            <TextField
              value={lastName.value}
              error={lastName.error}
              onChange={({target: {value}}) => lastName.change(value)}
              placeholder={t("pageTest.registration.lastName")}
              containerClassName={cx("FormRowField")}
            />
          ) : (
            FieldEmail
          )}
        </div>

        {isFinishing && (
          <div className={cx("FormRow")}>
            {FieldEmail}
            <div className={cx("InputAge")}>
              <TextField
                value={age.value}
                error={age.error}
                onChange={({
                             target: {
                               value,
                               validity: {valid},
                             },
                           }) => age.change(value && !valid ? age.value : value)}
                placeholder={t("pageTest.registration.age")}
                pattern="^\d{0,3}$"
                containerClassName={cx("FormRowField")}
              />
              {!age.value &&
                  <span className={cx("InputAgePlaceholder")}>({t("pageTest.registration.onlyNumber")})</span>}
            </div>
          </div>
        )}

        {!isFinishing && (
          <div className={cx("FormRow")}>
            <FacebookLogin
              appId={FACEBOOK_CLIENT_KEY}
              isMobile={true}
              autoLoad={false}
              disableMobileRedirect={true}
              fields="name,email"
              version='5.0'
              callback={onFacebookCallback}
              render={(renderProps: JSX.IntrinsicElements["button"]) => (
                <Button
                  className={cx("BtnSocial")}
                  type="button"
                  withArrow={false}
                  {...renderProps}
                >
                  <img src={require("assets/icons/fb_logo.svg")} alt=""/>
                  {t("pageTest.registration.fb")}
                </Button>
              )}
            />

            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              cookiePolicy={"single_host_origin"}
              onSuccess={onSucccesGoogleResponse}
              onAutoLoadFinished={(data) => {
              }}

              onFailure={(res) => {
              }}
              render={(renderProps) => (
                <Button
                  className={cx("BtnSocial")}
                  type="button"
                  withArrow={false}
                  {...renderProps}
                >
                  <img src={require("assets/icons/google_logo.svg")} alt=""/>
                  {t("pageTest.registration.google")}
                </Button>
              )}
            />
          </div>
        )}

        {isFinishing && (
          <Fragment>
            <Checkbox
              className={cx("CheckboxAgreeTerms")}
              label={t("pageTest.registration.agreeTerms")}
              checked={agreeTerms.value}
              onChange={(e) => agreeTerms.change(!agreeTerms.value)}
              error={agreeTerms.error}
            />
            <Checkbox
              label={t("pageTest.registration.agreeMailing")}
              checked={agreeMailing.value}
              onChange={(e) => agreeMailing.change(!agreeMailing.value)}
              error={agreeMailing.error}
            />
          </Fragment>
        )}
        <p className={cx("FormError")}>{error}</p>

        <div className={cx("FormRow")}>
          <Button
            loading={loadingStartSurvey || loadingFinishSurvey}
            className={cx("BtnSubmit")}
            type="submit"
          >
            {t("pageTest.registration.submit", {context: step})}
          </Button>
        </div>
      </form>
    </div>
  );
};

export const TextField = ({
                            className = "",
                            containerClassName = "",
                            error,
                            ...props
                          }: JSX.IntrinsicElements["input"] & {
  containerClassName?: string;
  error?: ReactNode;
}) => (
  <div className={cx("Field", containerClassName)}>
    <input
      {...props}
      className={cx("FieldInput", className, error && "error")}
    />
    <p className={cx("FieldError")}>{error}</p>
  </div>
);

export const Checkbox = ({
                           className = "",
                           checked,
                           label,
                           error,
                           onChange,
                           classNameLabel,
                           classNameError,
                           classNameWrapLabel,
                           classNameChecker,
                           classNameCheckerActive, disabled = false
                         }: {
  label?: ReactNode;
  error?: ReactNode;
  disabled?: boolean,
  classNameWrapLabel?: string,
  classNameLabel?: string,
  classNameError?: string
  classNameChecker?: string
  classNameCheckerActive?: string
} & JSX.IntrinsicElements["input"]) => {
  return (
    <div className={cx("CheckboxComponent", className, error && "error")}>
      <label className={cx("CheckboxComponentLabel", {CheckboxComponentLabelDisabled: disabled})}>
        <span
          className={`${cx("CheckboxComponentChecker",
            checked && "checked", checked && classNameCheckerActive)} ${classNameChecker ?? ''}`}
        />
        <input
          type="checkbox"
          onChange={onChange}
          disabled={disabled}
          className={cx("CheckboxComponentInput")}
        />
        <span className={`${cx("CheckboxComponentMain")} ${classNameWrapLabel ?? ''}`}>
          <span className={`${cx("CheckboxComponentLabelText")} ${classNameLabel ?? ''}`}>{label}</span>
          <span className={`${cx("CheckboxComponentError")} ${classNameError ?? ''}`}>{error}</span>
        </span>
      </label>
    </div>
  );
};

export default Registration;

import {parseError} from "api";
import {CurrencyEnum, LanguageEnum, ProviderEnum, useCreateOrdeMutation,} from "api/generated";
import {ROUTES} from "App";
import Preloader from "components/Preloader";
import PageError from "pages/Error";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router-dom";

export const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

const PageResultPay = ({
                         history,
                         match: {
                           params: {uid},
                         },
                       }: RouteComponentProps<{ uid: string }>) => {
  const [t, {language}] = useTranslation();
  const [error, setError] = useState(false);
  const [createOrderMutation] = useCreateOrdeMutation();

  useEffect(() => {
    createOrderMutation({
      variables: {
        language: language.toUpperCase() || language === "ru" ? LanguageEnum.Ru : LanguageEnum.Uk,
        provider: ProviderEnum.Fondy,
        currency: CurrencyEnum.Uah,
        suid: uid,
      },
    })
      .then(({data}) => {
        if (data?.createOrder?.order?.link) {
          window.open(data?.createOrder.order.link, "_self")
        } else if (data?.createOrder?.errors) {
          const message = data.createOrder.errors;
          if (message === "Survey is already paid") {
            history.push(ROUTES.result(uid));
          } else {
            setError(true);
          }
        } else {
          setError(true);
        }
      })
      .catch((e) => {
        const {message} = parseError(e);
        if (message === "Survey is already paid") {
          history.push(ROUTES.result(uid));
        } else {
          setError(true);
        }
      });
  }, []);

  if (error) return <PageError/>;
  return <Preloader/>;
};
export default PageResultPay;

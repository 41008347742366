import React, {Fragment, useState} from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import Button from "components/Button";
import {ROUTES} from "App";
import DoubleFrame from "components/DoubleFrame";
import {useTranslation} from "react-i18next";
import {useURL} from "../../../../hooks/useURL";

const cx = classNames.bind(styles);

type Props = {
  text: string[];
  btnText?: string;
} & JSX.IntrinsicElements['section']

const TextSectionFrame = ({btnText, text, className = '', ...props}: Props) => {
  const code = useURL().get("code")
  const goToTest = () => {
    if (code) {
      localStorage.setItem('code', code)
    }
    window.open(ROUTES.test, '_blank')?.focus()
  }
  const [t] = useTranslation()
  const [isUnwrap, setIsUnwrap] = useState(false)
  let content = text.reduce((acc, item) => acc += `<p>${item}</p>`, '');
  const handleClick = () => {
    if (isUnwrap) {
      goToTest()
    }
    setIsUnwrap(!isUnwrap)
  }
  if (!isUnwrap) {
    content = content.substr(0, 237) + '...'
  }
  return (
    <Fragment>
      <section className={cx("Component", className)} {...props}>
        <div className={cx("Content", "container")}>
          <h2 className={cx('Title')}>{t('authorTitle')}</h2>
          <div className={cx('Authors')}>
            <div className={cx("AuthorsItem")}>
              <div className={cx('AuthorsItemInfo')}>
                <img src={require('assets/images/author1.png')} alt=""/>
                <p>{t('author1.name')}</p>
              </div>
              <div className={cx('AuthorsItemDescription')}>{t('author1.description')}</div>
            </div>
            <div className={cx("AuthorsItem")}>
              <div className={cx('AuthorsItemInfo')}>
                <img src={require('assets/images/author2.png')} alt=""/>
                <p>{t('author2.name')}</p>
              </div>
              <div className={cx('AuthorsItemDescription')}>{t('author2.description')}</div>
            </div>
          </div>
          <DoubleFrame>
            <div className={cx("Description")} dangerouslySetInnerHTML={{__html: content}}/>
            <div className={cx('BtnWrap')}>
              <Button onClick={handleClick} withArrow arrowDirection={!isUnwrap ? 'bottom' : 'right'}
                      className={cx('Btn')}>{t(isUnwrap ? 'pageHome.model.you.btn' : 'moreInfo')}</Button>
            </div>
          </DoubleFrame>

        </div>

      </section>
    </Fragment>
  )
    ;
};

export default TextSectionFrame;

import React, { Fragment, ReactNode } from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as IconArrowRight } from "assets/icons/arrow-right.svg";
const cx = classNames.bind(styles);

const Arrows = () => (
  <Fragment>
    <span className={cx("Icon")}>
      <IconArrowRight />
    </span>
    <span className={cx("Icon")}>
      <IconArrowRight />
    </span>
    <span className={cx("Icon")}>
      <IconArrowRight />
    </span>
  </Fragment>
);

const Ticker = ({
  message,
  className = "",
  oneLine = false,
  animationDuration = '180s'
}: {
  message: ReactNode;
  className?: string;
  oneLine?: boolean;
  animationDuration?: string
}) => {
  const Text = () => <span>{message}</span>;
  const lineStyle = {
    animationDuration
}
  return (
    <div className={cx("Ticker", className)}>
      <div style={lineStyle} className={cx("Line")}>
        <div className={cx("Message")}>
          <Arrows />
          <Text />
          <Arrows />
          <Text />
        </div>
        <div className={cx("Message")}>
          <Arrows />
          <Text />
          <Arrows />
          <Text />
        </div>
        <div className={cx("Message")}>
          <Arrows />
          <Text />
          <Arrows />
          <Text />
        </div>
      </div>
      {!oneLine && (
        <div style={lineStyle} className={cx("Line")}>
          <div className={cx("Message")}>
            <Text />
            <Arrows />
            <Text />
            <Arrows />
          </div>
          <div className={cx("Message")}>
            <Text />
            <Arrows />
            <Text />
            <Arrows />
          </div>
          <div className={cx("Message")}>
            <Text />
            <Arrows />
            <Text />
            <Arrows />
          </div>
        </div>
      )}
    </div>
  );
};

export default Ticker;

import React, {FC} from 'react';


export const StarSvg: FC<StarSvgPropsType> = ({
                                                strokeWidth = '2',
                                                classNamesText,
                                                text,
                                                fill = '#C3F20C',
                                                stroke = 'black'
                                              }) => (
  <div className={'StarWrap'}>
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.4413 87H33.9212L34.2214 86.6256L44.3854 73.9495H44.6443L54.8083 86.6256L55.1085 87H55.5885H55.6777H56.5366L56.6662 86.1509L59.1222 70.0572L59.3385 69.9274L74.4883 75.843L75.0971 76.0807L75.5592 75.6186L75.6186 75.5592L76.0807 75.0971L75.843 74.4883L69.9274 59.3385L70.0572 59.1222L86.1509 56.6662L87 56.5366V55.6777V55.5885V55.1085L86.6256 54.8083L73.9495 44.6443V44.3854L86.6256 34.2214L87 33.9212V33.4413V33.3521V32.4931L86.1509 32.3635L70.0647 29.9087L69.9248 29.6689L75.8428 14.542L76.0811 13.933L75.6186 13.4705L75.5592 13.4111L75.0979 12.9498L74.4899 13.1861L59.3272 19.0779L59.1202 18.9596L56.6662 2.87887L56.574 2.27441L55.9939 2.08104L55.9047 2.05132L55.2432 1.83082L54.8076 2.3753L44.6437 15.0802H44.3861L34.2221 2.3753L33.7865 1.83082L33.125 2.05132L33.0359 2.08104L32.4558 2.27441L32.3635 2.87887L29.9107 18.9522L29.68 19.0804L14.5404 13.1863L13.9321 12.9495L13.4705 13.4111L13.4111 13.4705L12.9495 13.9321L13.1863 14.5404L19.0804 29.68L18.9522 29.9107L2.87887 32.3635L2.27441 32.4558L2.08104 33.0359L2.05132 33.125L1.83082 33.7865L2.3753 34.2221L15.0802 44.3861V44.6437L2.3753 54.8076L1.83082 55.2432L2.05132 55.9047L2.08104 55.9939L2.27441 56.574L2.87887 56.6662L18.9596 59.1202L19.0779 59.3272L13.1861 74.4899L12.9498 75.0979L13.4111 75.5592L13.4705 75.6186L13.933 76.0811L14.542 75.8428L29.6689 69.9248L29.9087 70.0647L32.3635 86.1509L32.4931 87H33.3521H33.4413Z"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}/>
    </svg>
    <span className={classNamesText ? classNamesText : ''}> {text}$</span>
  </div>

)

type StarSvgPropsType = {
  strokeWidth?: number | string
  fill?: string
  stroke?: string
  text: string | number
  classNamesText?: string
}

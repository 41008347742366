import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import Ticker from "pages/Landing/components/Ticker";
import { useTranslation } from "react-i18next";
import Logo from "assets/logo.svg";
import Button from "components/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "App";
const cx = classNames.bind(styles);

const Intro = ({ onClickStart }: { onClickStart(): void }) => {
  const [t] = useTranslation();
  return (
    <div className={cx("Component")}>
      <div className={cx("Content")}>
        <div className={cx("Container", "container")}>
          <Link to={ROUTES.home} className={cx("Logo")}>
            <img src={Logo} alt="Fly_academy" />
          </Link>
          <h1 className={cx("Title")}>{t("pageTest.intro.title")}</h1>
          <p className={cx("Description")}>{t("pageTest.intro.text")}</p>
          <Button onClick={onClickStart}
                  className={cx("BtnStart")}>
            {t("pageTest.intro.btn")}
          </Button>
        </div>
      </div>
      <Ticker className={cx("Ticker")} message={t("pageTest.intro.ticker")} />
    </div>
  );
};

export default Intro;
